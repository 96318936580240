import { useState } from "react";
import _ from "lodash";

const useOcFormEditorSelector = ({
  list,
  value,
  selectName = false,
  searchCode = false,
}) => {
  const [search, setSearch] = useState("");

  let selectedItem = list.find((item) => item.code === value);
  if (selectName) {
    selectedItem = list.find((item) => item.name === value);
  }

  let regexSearch = new RegExp(search, "i");
  let searchedList = _.filter(list, (obj) => {
    let list = regexSearch.test(obj.name);
    if (searchCode) {
      list = regexSearch.test(obj.code);
    }
    return list;
  });

  const initialListLength = 25;
  const [listLength, setListLength] = useState(initialListLength);
  const listForInfiniteScrolling = searchedList.slice(0, listLength);

  const handleSearch = (e) => {
    setSearch(e.target.value.replace(/[.,@*+?^${}()|[\]\\]/g, " "));
  };

  const handleClearSearch = () => {
    setSearch("");
  };

  const handleHasMore = () => {
    setListLength((prev) => prev + initialListLength);
  };

  return {
    list: listForInfiniteScrolling,
    search,
    setSearch,
    selectedItem,
    handleClearSearch,
    handleSearch,
    handleHasMore,
  };
};

export default useOcFormEditorSelector;
