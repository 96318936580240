import React from "react";
import Box from "@material-ui/core/Box";
import Avatar from "oc/components/oc-avatar";
import getFullName from "oc/utils/get-fullname";
import State from "my-leave/components/my-leave-state";
import { useTranslation } from "react-i18next";
import CalendarDay from "my-leave/components/my-leave-calendar-day";
import { InlineIcon } from "@iconify/react";
import ArrowIcon from "@iconify/icons-mdi/arrow-right-bold";
import useEmployee from "hooks/use-myhr-employee";

export default function LeaveCardHeader({ employee, leaveRequest, loading, isMobile }) {
  const { t } = useTranslation();

  const { data: approvedCode } = useEmployee({ employeeId: leaveRequest?.approvedBy });
  const { data: rejectedCode } = useEmployee({ employeeId: leaveRequest?.rejectedBy });

  return (
    <Box display="flex" flexDirection={isMobile ? "column-reverse" : "unset"}>
      <Box>
        {!isMobile && <Avatar
          employee={employee?.code}
          style={{
            width: "100px",
            height: "100px",
          }}
        />
        }      </Box>
      <Box flexGrow={1} padding="0px 0px 0px 16px">
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box fontSize="20px" letterSpacing="0.15px" paddingBottom="22px">
            {getFullName(employee)}
          </Box>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Box height="1px" />
            <Box fontSize="9px" style={{ color: "#919699" }}>
              {new Date(leaveRequest?.createdAt).toLocaleDateString()}
            </Box>
            <Box height="2px" />
            {(leaveRequest?.state === "approved" || leaveRequest?.state === "assigned") && (
              <Box fontSize="9px" paddingTop="1px" style={{ color: "#919699" }}>
                {getFullName(approvedCode)}
              </Box>
            )}
            {leaveRequest?.state === "rejected" &&
              <Box fontSize="9px" style={{ color: "#919699" }}>
                {getFullName(rejectedCode)}
              </Box>
            }
            <Box height="2px" />
            <State
              label={t(`Leave.${leaveRequest?.state}`)}
              state={leaveRequest?.state}
              loading={loading}
            />
          </Box>
        </Box>
        {isMobile && <Box height="20px" />}
        <Box display="flex" flexDirection="row" alignItems="center">
          <CalendarDay date={leaveRequest?.startDate} loading={loading} />
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            padding="4px"
          >
            <InlineIcon icon={ArrowIcon} />
          </Box>
          <CalendarDay date={leaveRequest?.endDate} loading={loading} />
          <Box padding="0px 20px" marginTop="-4px">
            <Box fontSize="16px" letterSpacing="0.44px">
              {t(`Leave.${leaveRequest?.leaveType}`)}
            </Box>
            <Box fontSize="14px" letterSpacing="0.25px" paddingTop="3px">
              {leaveRequest?.effectiveWorkDays?.toLocaleString()}{" "}
              {t("WORKDAYS")}
            </Box>

          </Box>
        </Box>
      </Box>
    </Box>
  );
}
