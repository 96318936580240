import React from "react";
import { Typography } from "@material-ui/core";
import { Box } from "@material-ui/core";
import CorporateSignature from "my-hr/components/my-hr-applications-data-panel/components/corporate-signature";
import ApplicationCheckBox from "my-hr/components/my-hr-applications-data-panel/components/application-check-box";
import useStyles from "./family-tax-credit-application-print.style";
import { DOTTED_LINE } from "my-hr/components/my-hr-applications-data-panel/constants";
import { useTranslation } from "react-i18next";
import formatTaxNumber from "oc/utils/format-tax-number";

const DependentsInfoTableRow = ({
  dependentQuality,
  name,
  taxNumber,
  dependencyTitle,
  changeDate,
}) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      style={{ margin: "8px 0px 8px 0px", fontSize: "15px" }}
    >
      <Box width="166px" textAlign="center" marginLeft="5px">
        {taxNumber ? taxNumber : DOTTED_LINE}
      </Box>
      <Box width="200px" textAlign="center" marginLeft="23px">
        {name}
      </Box>
      <Box width="50px" textAlign="center" marginLeft="15px">
        {dependentQuality}
      </Box>
      <Box width="50px" textAlign="center" marginLeft="45px">
        {dependencyTitle}
      </Box>
      <Box width="130px" textAlign="center" marginLeft="35px">
        {changeDate ? new Date(changeDate).toLocaleDateString() : ""}
      </Box>
    </Box>
  );
};
export const FamilyTaxCreditApplicationPrint = React.forwardRef(
  (props, ref) => {
    const {
      fullName = "Minta Borbála",
      taxNumber = "8124440505",
      applicationDate,
      aloneOrWithSpouse,
      employerName,
      employerTaxNumber,
      states,
    } = props;
    const spouseTaxNumber = states?.spouseTaxNumber || "";
    const spouseCompanyName = states?.spouseEmployerName || "";
    const spouseCompanyTaxNumber = formatTaxNumber(states?.spouseEmployerTaxNumber) || "";
    let thisYear = new Date(states?.dateOfDeclaration).getFullYear().toString();
    const classes = useStyles();
    const { t } = useTranslation();

    return (
      <Box ref={ref}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          fontFamily="EB Garamond"
          fontSize="15px"
          style={{ paddingTop: "30px" }}
        >
          <Box
            style={{
              fontSize: "16px",
              fontWeight: "bold",
            }}
          >
            Adóelőleg-nyilatkozat a családi kedvezmény és járulékkedvezmény
            érvényesítéséről
            <Box />
            <Box
              key="nyilatkozat-ev"
              display="flex"
              flexDirection="column"
              alignItems="center"
              paddingTop="2px"
            >
              A nyilatkozat benyújtásának éve: {thisYear}
            </Box>
          </Box>
          <Box className={classes.customBox}>
            <Box className={classes.separator} />
            <Box height="12px" />
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Box display="flex" flexDirection="column">
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  fontWeight="normal"
                >
                  A nyilatkozó magánszemély neve:
                  <Box margin="0px 5px">{fullName}</Box>
                  és
                </Box>
                <Box display="flex">
                  adóazonosító jele:
                  <Box margin="0px 5px">
                    {taxNumber ? taxNumber : DOTTED_LINE}
                  </Box>
                </Box>
              </Box>
              <Box>
                <Box display="flex" alignItems="center" flexDirection="column">
                  {t("MODIFIER_APPLICATION")}
                  <Box>
                    <ApplicationCheckBox
                      value={states?.isModifiedDeclaration}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box height="10px" />
            <Box
              style={{
                fontWeight: "bold",
                marginBottom: "10px",
              }}
            >
              <Box display="flex">
                <Box className={classes.startChapter}>1.</Box>
                <Box fontWeight="normal" display="flex">
                  Az eltartottak adatai:
                </Box>
              </Box>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-around"
            >
              <Box width="166px" textAlign="center">
                <Typography className={classes.tableTitle}>
                  Adóazonosító jel
                </Typography>
              </Box>
              <Box width="200px" textAlign="center" marginLeft="10px">
                <Typography className={classes.tableTitle}>Név</Typography>
              </Box>
              <Box width="50px" textAlign="center">
                <Typography className={classes.tableTitle}>EM*</Typography>
              </Box>
              <Box width="50px" textAlign="center" marginLeft="30px">
                <Typography className={classes.tableTitle}>JJ**</Typography>
              </Box>
              <Box width="130px" textAlign="center" marginLeft="20px">
                <Typography className={classes.tableTitle}>
                  Változás időpontja
                </Typography>
              </Box>
            </Box>
            {states?.dependents.map((item, index) => (
              <DependentsInfoTableRow
                key={`dependent_${index}`}
                dependentQuality={states?.dependents[index]?.dependentType}
                name={item.name}
                taxNumber={item.taxNumber}
                dependencyTitle={states?.dependents[index]?.entitlementTitle}
                changeDate={states?.dependents[index]?.changeDate}
              />
            ))}
            <Box height="5px" />
            <Typography className={classes.formatText}>
              EM* - Eltartotti minőség
            </Typography>
            <Box height="2px" />
            <Typography className={classes.formatText}>
              JJ** - Jogosultság jogcímei
            </Typography>
            <Box height="10px" />
            <Box>
              <Box display="flex">
                <Box className={classes.startChapter}>2.</Box>
                <Box fontWeight="normal" display="flex">
                  Nyilatkozom, hogy a családi kedvezményt{" "}
                  <b style={{ marginLeft: "5px" }}>egyedül</b>
                  <ApplicationCheckBox
                    value={aloneOrWithSpouse === "alone" ? "X" : ""}
                  />
                  ,
                  <Box width="3px" />
                  vagy jogosult házastársammal/élettársammal{" "}
                  <b style={{ marginLeft: "5px" }}>közösen</b>
                  <ApplicationCheckBox
                    value={aloneOrWithSpouse === "withSpouse" ? "X" : ""}
                  />
                </Box>
              </Box>
              <Box marginLeft="40px">érvényesítem.</Box>
            </Box>
            <Box height="10px" />
            <Box display="flex">
              <Box className={classes.startChapter}>3.</Box>
              <Box
                display="flex"
                fontWeight="normal"
                lineHeight="1.5"
                alignItems="flex-start"
                flexDirection="column"
                marginBottom="-4px"
              >
                Nyilatkozom, hogy jogosult vagyok a családi kedvezményt
                Magyarországon érvényesíteni, külföldi államban a
              </Box>
            </Box>
            <Box display="flex">
              <Box
                display="flex"
                fontWeight="normal"
                lineHeight="1.5"
                alignItems="flex-start"
                flexDirection="column"
                marginBottom="-4px"
                marginLeft="40px"
              >
                jövedelmem után azonos vagy hasonló kedvezményt nem veszek
                (vettem) igénybe.
              </Box>
              <ApplicationCheckBox value={states?.applyOnHungary} />
            </Box>
            <Box height="10px" />
            <Box display="flex">
              <Box className={classes.startChapter}>4.</Box>
              <Box fontWeight="normal" display="flex">
                Nyilatkozom, hogy nem kérem a családi járulékkedvezmény havi
                összegének érvényesítését.
                <ApplicationCheckBox value={states?.noRequestRelief} />
              </Box>
            </Box>
            <Box height="10px" />
            <Box display="flex">
              <Box className={classes.startChapter}>5.</Box>
              <Box display="flex" alignItems="center">
                A fentiek alapján nyilatkozom, hogy a családi kedvezményt (az a)
                vagy b) sor egyikét töltse ki!)
              </Box>
            </Box>
            <Box height="5px" />
            <Box display="flex" marginLeft="60px">
              <Box style={{ fontStyle: "italic" }}>a.</Box>
              <Box width="80px" style={{ textAlign: "center" }}>
                {states?.taxReliefAmount ? states?.taxReliefAmount : "-"}
              </Box>
              <b>forint összegben kívánom igénybe venni.</b>
            </Box>
            <Box height="5px" />
            <Box display="flex" marginLeft="60px">
              <Box style={{ fontStyle: "italic" }}>b.</Box>
              <Box width="80px" style={{ textAlign: "center" }}>
                {states?.taxReliefNumberOfChild
                  ? states?.taxReliefNumberOfChild
                  : "-"}
              </Box>
              <b>fő kedvezményezett eltartott után kívánom igénybe venni.</b>
            </Box>
            <Box height="10px" />
            <Box display="flex">
              <Box className={classes.startChapter}>6.</Box>
              Felelősségem tudatában kijelentem, hogy a nyilatkozat tartalmát
              házastársam, élettársam megismerte és elfogadta.
            </Box>
            <Box height="20px" />
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              marginLeft="40px"
            >
              <Box
                style={{
                  display: "flex",
                }}
              >
                Kelt:
                <Box width="150px" textAlign="center">
                  {applicationDate}
                </Box>
              </Box>
            </Box>
            <Box height="5px" />
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              marginLeft="40px"
            >
              A nyilatkozatot tevő magánszemély aláírása:
              <Box marginRight="40px">
                .............................................................
              </Box>
            </Box>
            <Box height="10px" />
            <Box className={classes.separator} />
            <Box height="10px" />
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box display="flex">
                <Box className={classes.startChapter}>7.</Box>
                <Box fontWeight="normal">
                  A családi kedvezményt kérő magánszemély jogosult
                  házastársa/élettársa
                </Box>
              </Box>
            </Box>
            <Box>
              <Box
                display="flex"
                alignItems="center"
                style={{
                  margin: "15px 0px 5px 40px",
                }}
              >
                neve:
                <Box width="200px" textAlign="center">
                  {aloneOrWithSpouse === "alone"
                    ? DOTTED_LINE
                    : states?.spouseName
                      ? states?.spouseName
                      : DOTTED_LINE}
                  ,
                </Box>
                <Box
                  style={{
                    marginLeft: "100px",
                  }}
                >
                  adóazonosító jele:
                </Box>
                <Box marginLeft="20px">
                  {aloneOrWithSpouse === "alone"
                    ? DOTTED_LINE
                    : spouseTaxNumber
                      ? spouseTaxNumber
                      : DOTTED_LINE}
                </Box>
              </Box>
            </Box>
            <Box height="5px" />
            <Box display="flex" marginLeft="25px">
              adóelőleget megállapító munkáltatója, kifizetője megnevezése:
              <Box marginLeft="20px"></Box>
              {aloneOrWithSpouse === "alone"
                ? DOTTED_LINE
                : spouseCompanyName
                  ? spouseCompanyName
                  : DOTTED_LINE
              }
            </Box>
            <Box height="10px" />
            <Box display="flex" marginLeft="25px">
              adószáma:
              <Box marginLeft="20px" display="initial"></Box>
              {aloneOrWithSpouse === "alone"
                ? DOTTED_LINE
                : spouseCompanyTaxNumber
                  ? spouseCompanyTaxNumber
                  : DOTTED_LINE
              }
            </Box>
            <Box height="20px" />
            <Box className={classes.separator} />
            <Box height="10px" />

            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box fontWeight="normal">
                A nyilatkozó magánszemély munkáltatójaként a nyilatkozat
                tartalmát tudomásul vettem. A magánszemély adóelőlegét a
                nyilatkozat figyelembevételével állapítom meg.
              </Box>
            </Box>
            <Box height="15px" />
            <Box display="flex" alignItems="center">
              A munkáltató, kifizető megnevezése:
              <Box width="250px" textAlign="center">
                {employerName ? employerName : DOTTED_LINE}
              </Box>
            </Box>
            <Box height="10px" />
            <Box display="flex" alignItems="center">
              adószáma:
              <Box marginLeft="25px" textAlign="center">
                {employerTaxNumber ? formatTaxNumber(employerTaxNumber) : DOTTED_LINE}
              </Box>
            </Box>
            <Box height="30px" />
            <CorporateSignature
              applicationDate={applicationDate}
              orientation="vertical"
            />
          </Box>
        </Box>
      </Box>
    );
  }
);

export default FamilyTaxCreditApplicationPrint;
