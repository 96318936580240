import moment from "moment";

export const parseActions = ({
  requestsEnabled = true,
  editWorkingTimeEnabled = true,
  action,
  row,
  list,
  role,
  setOpenNewRequestDialog,
  setOpenInfoDialog,
  setOpenShortInfoDialog,
  history,
  employeeCode,
  contractCode,
  recordingPeriod,
  isNotExaminer
}) => {
  if (recordingPeriod) {
    if (
      moment(row?.date).format("YYYY-MM-DD") < recordingPeriod?.startDate ||
      moment(row?.date).format("YYYY-MM-DD") > recordingPeriod?.endDate
    ) {
      if (row?.startTime && row?.endTime) {
        setOpenInfoDialog({ state: true, data: row });
        return;
      } else {
        return;
      }
    }
  }

  if (role === "user") {
    if (!requestsEnabled) {
      if (action === "add") {
        return;
      }
      setOpenInfoDialog({ state: true, data: row });
      return;
    }

    /** Substitutes */
    if (action === "add" && row?.myTimeType === "OVERTIME") {
      const isThereOvertime = list?.rows?.find(
        (item) =>
          moment(item?.date).format("YYYY-MM-DD") ===
          moment(row?.date).format("YYYY-MM-DD") &&
          item?.myTimeType === "OVERTIME"
      );
      const isThereAbsence = list?.rows?.find(
        (item) =>
          moment(item?.date).format("YYYY-MM-DD") ===
          moment(row?.date).format("YYYY-MM-DD") &&
          (item?.myTimeType === "LEAVE" ||
            item?.myTimeType === "ABSENCE" ||
            item?.myTimeType === "ON_CALL")
      );

      const isThereStandBy = list?.rows?.some((item) => {
        if (item?.myTimeType === "STAND_BY") {
          const itemStartDate = moment(item?.startTime);
          const itemEndDate = moment(item?.endTime);
          const rowDate = moment(row?.date);
          return rowDate.isBetween(itemStartDate, itemEndDate, "day", "[]");
        }
        return false;
      });

      const isThereWorkingTime = list?.rows?.find(
        (item) =>
          moment(item?.date).format("YYYY-MM-DD") ===
          moment(row?.date).format("YYYY-MM-DD") &&
          item?.myTimeType === "WORKING_TIME"
      );

      if (isThereOvertime && !isThereStandBy) {
        setOpenShortInfoDialog({
          state: true,
          text: "ONE_OVERTIME_ALLOWED_TEXT",
        });
        return;
      }

      if (isNotExaminer) {
        setOpenShortInfoDialog({
          state: true,
          text: "NOT_SET_EXAMINER_INFO",
        });
        requestsEnabled = false;
        return;
      }


      if (isThereAbsence) {
        setOpenShortInfoDialog({
          state: true,
          text: "OVERTIME_NOT_ALLOWED_TEXT",
        });
        return;
      }

      if (isThereStandBy) {
        row.showMyTimeTypeSelect = true;
      }

      if (isThereWorkingTime) {
        row.start = isThereWorkingTime.endTime;
        row.startTime = isThereWorkingTime.endTime;
      }

      setOpenNewRequestDialog({
        state: true,
        action,
        data: row,
      });
      return;
    }
    if (
      (action === "add" && row?.myTimeType === "WORKING_TIME") ||
      (action === "add" && row?.dayType === "REST_DAY" && !row?.myTimeType) ||
      (action === "add" &&
        row?.dayType === "PAID_HOLLIDAY" &&
        !row?.myTimeType) ||
      (action === "add" && row?.myTimeType === "OVERTIME")
    ) {
      const data = {
        myTimeType: "OVERTIME",
        date: row?.date,
        dayType: row?.dayType,
        start: "",
        startTime: "",
        end: "",
        endTime: "",
        timeDataTypeCode: "",
        timeDataTypeName: "",
      };
      setOpenNewRequestDialog({
        state: true,
        action,
        data,
      });
    }
    if (action === "add" && row?.myTimeType === "STAND_BY") {
      const data = {
        myTimeType: "STAND_BY_CALL",
        date: row?.date,
        dayType: row?.dayType,
        start: "",
        startTime: "",
        end: "",
        endTime: "",
        originalStartTime: row?.startTime,
        originalEndTime: row?.endTime,
        showMyTimeTypeSelect: true,
      };
      const resData = { ...row, ...data };
      setOpenNewRequestDialog({
        state: true,
        action,
        data: resData,
      });
    }
    if (action === "add" && row?.myTimeType === "STAND_BY_CALL") {
      const data = {
        myTimeType: "STAND_BY_CALL",
        date: row?.date,
        dayType: row?.dayType,
        start: "",
        startTime: "",
        end: "",
        endTime: "",
        originalStartTime: row?.startTime,
        originalEndTime: row?.endTime,
      };
      const resData = { ...row, ...data };
      console.log(resData);
      setOpenNewRequestDialog({
        state: true,
        action,
        data: resData,
      });
    }
    if (
      action === "edit" &&
      row?.myTimeType === "WORKING_TIME" &&
      editWorkingTimeEnabled
    ) {
      const data = {
        ...row,
      };
      setOpenNewRequestDialog({
        state: true,
        action,
        data,
      });
    }
    if (
      action === "edit" &&
      row?.myTimeType === "WORKING_TIME" &&
      !editWorkingTimeEnabled
    ) {
      const data = {
        ...row,
      };
      setOpenInfoDialog({ state: true, data });
    }
    if (action === "show" && row?.myTimeType === "STAND_BY") {
      setOpenInfoDialog({ state: true, data: row });
      return;
    }
    if (row?.timeDataTypeCode === "Tö" && !row?.state) {
      setOpenInfoDialog({ state: true, data: row });
      return;
    }
    if (row?.myTimeType === "OTHER") {
      setOpenInfoDialog({ state: true, data: row });
      return;
    }
    if (
      action === "edit" &&
      (row?.myTimeType === "LEAVE" ||
        row?.myTimeType === "ABSENCE" ||
        row?.myTimeType === "HOMEOFFICE" ||
        row?.myTimeType === "ON_CALL")
    ) {
      setOpenInfoDialog({ state: true, data: row });
    }
    if (
      action === "edit" &&
      row?.myTimeType === "STAND_BY_CALL" &&
      row?.__typename === "AdmissionResponse"
    ) {
      return setOpenInfoDialog({ state: true, data: row });
    }
    if (action === "show" && row?.state) {
      const url = `/my-time/show-request/${employeeCode}/${contractCode}/${row._id}`;
      history.push(url);
    }
    if (action === "show" && row?.myTimeType === "WORKING_TIME" && !row?._id) {
      setOpenInfoDialog({ state: true, data: row });
    }
    if (
      action === "edit" &&
      row?.myTimeType === "WORKING_TIME_ITEM" &&
      !row?._id
    ) {
      setOpenInfoDialog({ state: true, data: row });
    }
    if (action === "show" && row?.myTimeType === "OVERTIME" && row?._id) {
      const url = `/my-time/show-request/${employeeCode}/${contractCode}/${row._id}`;
      history.push(url);
    }
    if (action === "show") {
      setOpenInfoDialog({ state: true, data: row });
    }
    /** Rules */
    return { action, row };
  }
  if (role === "examiner") {
    if (row?.state) {
      const url = `/my-time/employee-request/${employeeCode}/${contractCode}/${row._id}`;
      history.push(url);
    } else {
      setOpenInfoDialog({ state: true, data: row });
    }
    if (action === "edit" && row?.state) {
      const url = `/my-time/employee-request/${employeeCode}/${contractCode}/${row._id}`;
      history.push(url);
    }
    if (!row?.state) {
      setOpenInfoDialog({ state: true, data: row });
    }
  }
};

export default parseActions;
