/**
 * Simple confirmation dialog for user's decisions
 * @author Pál Pintér
 * @createdAt 2022.04.27
 */
import React from "react";
import Dialog from "@material-ui/core/Dialog";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import useIsMobile from 'hooks/use-is-mobile/use-is-mobile';

export default function OcConfirmationDialog({
  showDialog,
  onClose,
  onCancel,
  title,
  text,
  okButtonText,
  cancelButtonText,
}) {
  const isMobile = useIsMobile();

  return (
    <Dialog
      aria-labelledby="confirm-dialog"
      open={showDialog}
    >
      <Box
        id="my-cafeteria-confirmation-dialog"
        width={isMobile ? "auto" : "432px"}
        minHeight={isMobile ? "auto" : "239px"}
        padding="24px"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Typography variant="h6" style={{ textAlign: isMobile ? "center" : "unset" }}>{title}</Typography>
        <Box height="36px" />
        <Typography>{text}</Typography>
        <Box height="36px" />
        <Button
          id="my-cafeteria-confirmation-dialog-ok-button"
          onClick={onClose}
          fullWidth
          variant="contained"
          color="primary"
          disableElevation
          style={{ padding: "9px 0px" }}
        >
          {okButtonText}
        </Button>
        <Box height="36px" />
        <Button
          id="my-cafeteria-confirmation-dialog-cancel-button"
          onClick={onCancel}
          fullWidth
          style={{ padding: "9px 0px" }}
        >
          {cancelButtonText}
        </Button>
      </Box>
    </Dialog>
  );
}
