import React from "react";
import OcCard from "oc/components/oc-card";
import { Box } from "@material-ui/core";
import TaxApplicationSelector from "../tax-application-selector/tax-application-selector";

const CurrentApplications = ({ label }) => {
  return (
    <OcCard label={label}>
      <Box>
        <TaxApplicationSelector
          label={
            "Adóelőleg-nyilatkozat a családi kedvezmény és járulékkedvezmény érvényesítéséről"
          }
          type="TaxDeclarationFamily"
        />
        <Box height="16px" />
        <TaxApplicationSelector
          label={
            "Adóelőleg-nyilatkozat az első házasok kedvezményének érvényesítéséről"
          }
          type="TaxDeclarationFirstMarriedPerson"
        />
        <Box height="16px" />
        <TaxApplicationSelector
          label={
            "Adóelőleg-nyilatkozat a négy vagy több gyermeket nevelő anyák kedvezményének érvényesítéséről"
          }
          type="TaxDeclarationFourChildren"
        />
        <Box height="16px" />
        <TaxApplicationSelector
          label={"Adóelőleg-nyilatkozat a személyi kedvezmény érvényesítéséről"}
          type="TaxDeclarationPersonal"
        />
        <Box height="16px" />
        <TaxApplicationSelector
          label={
            "Adóelőleg-nyilatkozat a 30 év alatti anyák kedvezményének érvényesítéséről"
          }
          type="TaxDecarationMotherUnder30"
        />
        <Box height="16px" />
        <TaxApplicationSelector
          label={
            "Nyilatkozat a 25 év alatti fiatalok kedvezményének részben vagy egészben történő érvényesítésének mellőzéséről"
          }
          type="TaxDeclarationUnder25"
        />
      </Box>
    </OcCard>
  );
};

export default CurrentApplications;
