import React, { useEffect } from "react";
import OcPageTransition from "oc/components/oc-page-transition";
import Box from "@material-ui/core/Box";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { selectedTabState } from "states";
import TabPanel from "oc/components/oc-tab-panel";
import LeaveDashboard from "./components/leave-dashboard/leave-dashboard";
import useCurrentUserState from "hooks/use-current-user-state";
import useUserRolesDataByContract from "hooks/use-myhr-user-roles-data-by-contract";
import useIsMobile from 'hooks/use-is-mobile/use-is-mobile';

export default function MyLeaveExaminerDashboardPage() {
  const { t } = useTranslation();
  const { contractCode } = useCurrentUserState();
  const isMobile = useIsMobile();
  const [selectedTab, setSelectedTab] = useRecoilState(selectedTabState("leaveExaminerDashboard"));

  const { data: rolesData } = useUserRolesDataByContract({ contract: contractCode });
  const roles = [
    { key: "examiner", label: t("EXAMINERS_CONTROL_PANEL") },
    { key: "assigner", label: t("ASSIGNER_CONTROL_PANEL") },
    { key: "viewer", label: t("VIEWERS_CONTROL_PANEL") }
  ].filter(role => rolesData?.some(item => item.product === "MyLeave" && item.role === role.key));

  useEffect(() => {
    if (roles.length === 1) setSelectedTab(0);
  }, [roles.length, setSelectedTab]);

  return (
    <OcPageTransition>
      <Box>
        <Box padding={isMobile ? "16px 0px 0px 0px" : "16px 16px 0px 16px"}>
          <Box bgcolor="#E3E5E5">
            <Tabs
              value={selectedTab}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              onChange={(e, newValue) => setSelectedTab(newValue)}
              aria-label="tabs example"
            >
              {roles.map((role, index) => (
                <Tab key={role.key} label={role.label} />
              ))}
            </Tabs>
          </Box>
          {roles.map((role, index) => (
            <TabPanel key={role.key} value={selectedTab} index={index}>
              <LeaveDashboard role={role.key} />
            </TabPanel>
          ))}
        </Box>
      </Box>
    </OcPageTransition>
  );
}
