import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { InlineIcon } from "@iconify/react";
import SendIcon from "@iconify/icons-mdi/send";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import useClientState from "hooks/use-oc-client-state";
import { useMutation } from "@apollo/react-hooks";
import _ from "lodash";
import { GET_LEAVE_REQUEST } from "graphql/queries";
import { CREATE_LEAVE_MESSAGE } from "graphql/mutations";
import useStyles from "./my-leave-new-message.style";
import { useTheme } from "@material-ui/core/styles";

const MyLeaveNewMessage = ({
  loading,
  selectedRequest,
  selectedEmployeeId,
  disabled,
  role
}) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  const [newMessageText, setNewMessageText] = useClientState(
    "newMessageText",
    ""
  );

  const [newMessageError, setNewMessageError] = useClientState(
    "newMessageError",
    false
  );

  const [createLeaveMessage] = useMutation(CREATE_LEAVE_MESSAGE);

  const handleNewMessage = (e) => {
    setNewMessageText(e.target.value);
  };

  const handleSubmitNewMessage = () => {
    if (newMessageText === "") {
      setNewMessageError(true);
      return;
    }
    let variables = {
      request: selectedRequest,
      message: newMessageText,
      locale: i18n.language,
      role: role
    };
    createLeaveMessage({
      variables: variables,
      optimisticResponse: {
        __typename: "Mutation",
        createLeaveMessage: {
          __typename: "LeaveMessage",
          createdAt: new Date().toUTCString(),
          createdBy: selectedEmployeeId,
          message: newMessageText,
          employeeAvatar: "",
          employeeName: "temporary",
        },
      },
      update: (proxy, { data: { createLeaveMessage } }) => {
        const data = proxy.readQuery({
          query: GET_LEAVE_REQUEST,
          variables: { _id: selectedRequest },
        });
        let newData = _.cloneDeep(data);

        newData.leaveRequest.messages = [
          ...newData.leaveRequest.messages,
          createLeaveMessage,
        ];

        proxy.writeQuery({
          query: GET_LEAVE_REQUEST,
          data: newData,
        });
      },
    });

    setNewMessageText("");
  };

  useEffect(() => {
    return () => {
      setNewMessageText("");
      setNewMessageError(false);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <TextField
      //className={classes.textInput}
      id="new-message-box-input"
      multiline
      fullWidth
      variant="outlined"
      placeholder={t("Leave.writeMessage")}
      value={newMessageText}
      onChange={handleNewMessage}
      onFocus={() => setNewMessageError(false)}
      error={newMessageError}
      helperText={
        newMessageError
          ? `${t("EMPTY_MESSAGE")}`
          : `${newMessageText.length}/255`
      }
      style={{
        height: "auto",
      }}
      disabled={disabled}
      inputProps={{ maxLength: 255 }}
      FormHelperTextProps={
        newMessageError
          ? { style: { textAlign: "left" } }
          : { style: { textAlign: "right" } }
      }
      InputProps={{
        disabled: loading || disabled,
        style: {
          backgroundColor: "white",
        },
        endAdornment: (
          <InputAdornment position="end">
            <InlineIcon
              id="my-leave-new-message-button"
              disabled={disabled}
              icon={SendIcon}
              className={classes.iconSend}
              onClick={handleSubmitNewMessage}
              style={{
                cursor: "pointer",
                backgroundColor: theme.palette.gray20.main,
                padding: "13px",
                borderRadius: "25px"
              }}
            />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default MyLeaveNewMessage;
