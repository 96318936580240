import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.gray20.main,
    },
  },
  selectedRow: {
    backgroundColor: theme.palette.gray20.main,
  },
}));

export default useStyles;
