import React from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import useStyles from "../my-leave-leave-base-card/my-leave-leave-base-card.style";
import useLeaveSummaryChartData from "hooks/use-my-leave-summary-chart-data";
import { useTranslation } from "react-i18next";

const MyLeaveBarDashboard = ({ id, row }) => {
  const { t } = useTranslation();
  let thisYear = new Date().getFullYear().toString();

  const { data, noLeave } = useLeaveSummaryChartData({
    year: thisYear,
    contract: row?.contract,
  });

  const classes = useStyles();

  let availableLeavePercentage = (
    (100 * Math.abs(data?.availableLeaveDays || 0)) /
    data?.allLeaveDays
  ).toFixed(2);

  let requestedLeavePercentage = (
    (100 * Math.abs(data?.requestedLeaveDays || 0)) /
    data?.allLeaveDays
  ).toFixed(2);

  let fulfilledLeavePercentage = (
    (100 * Math.abs(data?.fulfilledLeaveDays || 0)) /
    data?.allLeaveDays
  ).toFixed(2);

  return (
    <Grid
      item
      sm={6}
      md={6}
      lg={12}
      style={{
        borderRadius: 8,
        marginTop: 16,
      }}
    >
      <Box id={id} className={classes.leaveLineChart}>
        {!noLeave ? (
          <>
            <Box
              className={classes.leaveTooltipLeaveTitleLine}
              width={`${availableLeavePercentage}%`}
            >
              <Box
                className={
                  data?.requestedLeaveDays === 0 &&
                    data?.fulfilledLeaveDays === 0
                    ? classes.availableLeaveDaysEdited
                    : classes.availableLeaveDays
                }
              >
                {data?.availableLeaveDays?.toLocaleString()}
              </Box>
            </Box>
            <Box
              className={classes.leaveTooltipLeaveTitleLine}
              width={`${requestedLeavePercentage}%`}
            >
              <Box
                className={
                  data?.availableLeaveDays === 0 &&
                    data?.fulfilledLeaveDays === 0
                    ? classes.requestedLeaveDaysEditedTLTR
                    : data?.fulfilledLeaveDays === 0
                      ? classes.requestedLeaveDaysEditedTR
                      : data?.availableLeaveDays === 0
                        ? classes.requestedLeaveDaysEditedTL
                        : data?.requestedLeaveDays === 0
                          ? classes.fulfilledLeaveDaysEditedTR
                          : classes.requestedLeaveDays
                }
              >
                {data?.requestedLeaveDays?.toLocaleString()}
              </Box>
            </Box>
            <Box
              className={classes.leaveTooltipLeaveTitleLine}
              width={`${fulfilledLeavePercentage}%`}
            >
              <Box
                className={
                  data?.availableLeaveDays === 0 &&
                    data?.requestedLeaveDays === 0
                    ? classes.fulfilledLeaveDaysEditedTLTR
                    : classes.fulfilledLeaveDays
                }
              >
                {data?.fulfilledLeaveDays?.toLocaleString()}
              </Box>
            </Box>
          </>
        ) : (
          <Box>{t("NO_LEAVE")}</Box>
        )}
      </Box>
    </Grid>
  );
};

export default MyLeaveBarDashboard;
