import gql from "graphql-tag";

export const MY_TIME_USERS_WITH_REQUEST = gql`
  mutation myTimeUsersWithRequest(
    $requestsOnly: Boolean!
    $showOvertime: Boolean!
    $showWorkingTimeChange: Boolean!
    $showStandbyCall: Boolean!
    $roleFilters: RoleFilterInput
    $sortField: String!
    $sortDirection: String!
    $searchField: String
    $searchText: String
    $startDate: Date!
    $endDate: Date!
    $page: Int!
    $pageSize: Int!
  ) {
    myTimeUsersWithRequest(
      requestsOnly: $requestsOnly
      showOvertime: $showOvertime
      showWorkingTimeChange: $showWorkingTimeChange
      showStandbyCall: $showStandbyCall
      roleFilters: $roleFilters
      sortField: $sortField
      sortDirection: $sortDirection
      searchField: $searchField
      searchText: $searchText
      startDate: $startDate
      endDate: $endDate
      page: $page
      pageSize: $pageSize
    ) {
      rows {
        contract
        employee
        name
        orgUnit
        jobClass
        requestCount
        _id
      }
      total
      page
      pageSize
      totalPages
      allRequestCount
      allUsersCount
      startTime
      endTime
    }
  }
`;
