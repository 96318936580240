export const overlappingTimePeriodError = ({ error, setError }) => {
  const messagesArray = error.messages;
  messagesArray.push("OVERLAPPING_TIME_PERIODS");
  return setError({
    state: true,
    messages: messagesArray,
  });
};

export const exceedsVoluntaryOvertimeLimitError = ({ error, setError }) => {
  const messagesArray = error.messages;
  messagesArray.push("EXCEEDS_WEEKLY_VOLUNTARY_OVERTIME_LIMIT");
  return setError({
    state: true,
    messages: messagesArray,
  });
};

export const exceedsWeeklyWorktimeLimitError = ({ error, setError }) => {
  const messagesArray = error.messages;
  messagesArray.push("EXCEEDS_WEEKLY_WORKTIME_LIMIT");
  return setError({
    state: true,
    messages: messagesArray,
  });
};

export const timePeriodNotAppropriateError = ({ error, setError }) => {
  const messagesArray = error.messages;
  messagesArray.push("TIME_PERIOD_NOT_APPROPRIATE");
  return setError({
    state: true,
    messages: messagesArray,
  });
};

export const exceedsDailyWorkTimeLimitError = ({ error, setError }) => {
  const messagesArray = error.messages;
  messagesArray.push("EXCEEDS_DAILY_WORKTIME_LIMIT");
  setError({
    state: true,
    messages: messagesArray,
  });
};

export const exceedsStandByIntervallError = ({ error, setError }) => {
  const messagesArray = error.messages;
  messagesArray.push("EXCEEDS_STAND_BY_INTERVALL");
  return setError({
    state: true,
    messages: messagesArray,
  });
};
