import moment from "moment";

const leaveCoverage = ({ startDate, endDate, leaveType }, leaveRequests) => {
  let start = moment(startDate).startOf("day");
  let end = moment(endDate).endOf("day");
  let coverage = false;

  const checkCoverage = (date) => {
    return leaveRequests.some(item => {
      let itemStart = moment(item.startDate).startOf("day");
      let itemEnd = moment(item.endDate).endOf("day");
      let sameType = ["A", "B", "C", "D", "E", "F", "G", "H", "K", "L", "M", "N", "P", "Q", "R", "S", "T", "U", "V"].includes(item.leaveType);
      let exceptionalCoverage = (leaveType === "O" || leaveType === "I" || leaveType === "J") && item.leaveType === "S";

      return (
        date.isBetween(itemStart, itemEnd, undefined, "[]") &&
        item.state !== "rejected" &&
        (sameType || exceptionalCoverage)
      );
    });
  };

  for (let date = start.clone(); date.isSameOrBefore(end); date.add(1, 'day')) {
    if (checkCoverage(date)) {
      coverage = true;
      break;
    }
  }
  return coverage;
};

export default leaveCoverage;
