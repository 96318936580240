import React, { useRef, useEffect, useState, useLayoutEffect } from "react";
import { Box, Typography } from "@material-ui/core";
import ApplicationHeader from "../application-header/application-header";
import ApplicationCardInfoItem from "../application-card-info-item/application-card-info-item";
import { useReactToPrint } from "react-to-print";
import MyPersonalTaxCreditApplicationPrint from "../my-personal-tax-credit-application-print/my-personal-tax-credit-application-print";
import OcDatePicker from "oc/components/oc-date-picker";
import { useTranslation } from "react-i18next";
import OcDownload from "oc/components/oc-download";
import { useRecoilState, useResetRecoilState } from "recoil";
import TextField from "@material-ui/core/TextField";
import getDateFormatByLocale from "oc/utils/get-date-format-by-locale";
import useContractsByEmployee from "hooks/use-myhr-contracts-by-employee";
import useCurrentUserState from "hooks/use-current-user-state";
import TaxCreditApplicationButtonBars from "../tax-credit-application-buttonbars/tax-credit-application-buttonbars";
import TaxCreditApplicationCheckboxLine from "../tax-credit-application-checkbox-line/tax-credit-application-checkbox-line";
import { taxDeclarationPersonalState } from "my-hr/components/my-hr-applications-data-panel/states/tax-declaration-personal-state";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { GET_TAX_DECLARATION_PERSONAL } from "graphql/queries";
import { CREATE_TAX_DECLARATION_PERSONAL } from "graphql/mutations";
import ModifiedStatementCheck from "../modified-statement-check/modified-statement-check";
import OcCard from "oc/components/oc-card";
import TaxCreditApplicationErrorBox from "my-hr/components/my-hr-applications-data-panel/components/tax-credit-application-error-box/tax-credit-application-error-box";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import useOcSnackbar from "hooks/use-oc-snackbar";
import { useTheme } from "@material-ui/styles";

const useFormData = ({ _id, setSelected }) => {
  const { t } = useTranslation();
  const [saveErrors, setSaveErrors] = useState([]);
  const [disabledButton, setDisabledButton] = useState(false);
  const [states, setStates] = useRecoilState(taxDeclarationPersonalState);
  const resetStates = useResetRecoilState(taxDeclarationPersonalState);

  const { data, loading, error } = useQuery(GET_TAX_DECLARATION_PERSONAL, {
    fetchPolicy: "cache-and-network",
    skip: !_id,
    variables: {
      _id: parseInt(_id),
    },
    onCompleted: (data) => {
      const result = data?.taxDeclarationPersonal;
      const newStates = { ...result };
      newStates.dateOfDeclaration = new Date(result?.dateOfDeclaration);
      delete newStates.__typename;
      setStates(newStates);
    },
  });

  const result = data?.taxDeclarationPersonal;

  const handleChange = ({ key, value }) => {
    const newState = { ...states, [key]: value };
    setStates(newState);
  };

  const { setOpenMessage } = useOcSnackbar();
  const [saveDeclaration, { loading: mutationLoading }] = useMutation(
    CREATE_TAX_DECLARATION_PERSONAL,
    {
      onCompleted: (data) => {
        const res = data?.createTaxDeclarationPersonal;
        setDisabledButton(false);
        if (res?.result) {
          if (res?.messages?.length > 0) {
            const message = res?.messages[0];
            if (
              message === "A korábban beadott nyilatkozata törlésre került!"
            ) {
              setOpenMessage({
                type: "success",
                message: t("TAX_DECLARATION_CREATED_SUCCESSFULLY"),
              });
              setOpenMessage({
                type: "warning",
                message: t("PREVIOUS_TAX_DECLARATION_DELETED"),
              });
            }
          } else {
            setOpenMessage({
              type: "success",
              message: t("TAX_DECLARATION_CREATED_SUCCESSFULLY"),
            });
          }
          setSaveErrors([]);
          const params = {
            editable: false,
            tableId: res?.tableId,
            year: states?.dateOfDeclaration?.getFullYear().toString(),
            type: "TaxDeclarationPersonal",
            state: "requested",
          };
          setSelected(params);
        } else {
          setSaveErrors(res?.messages);
          setDisabledButton(false);
        }
      },
      onError: () => {
        setSaveErrors([t("TAX_DECLARATION_CREATED_FAILED")]);
        setDisabledButton(false);
      },
    }
  );

  useEffect(() => {
    if (!states?.isModifiedDeclaration) {
      handleStateChange({ ...states, noRequestRelief: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [states?.isModifiedDeclaration]);


  const handleStateChange = (newState) => {
    setStates(newState);
  };

  const handleSave = () => {
    setDisabledButton(true);
    const params = { ...states };
    saveDeclaration({ variables: params });
  };

  return {
    data: result,
    loading: loading || mutationLoading,
    error,
    states,
    disabledButton,
    resetStates,
    handleChange,
    handleSave,
    saveErrors,
    setSaveErrors,
    handleStateChange,
  };
};

const MyPersonalTaxCreditApplication = ({
  fullName,
  taxNumber,
  taxApplicationSaveEnabled,
  handleRemoveRequest,
  selected,
  setSelected,
  isMobile
}) => {
  const editable = selected?.editable;
  const history = useHistory();
  const theme = useTheme();

  const title = "Adóelőleg-nyilatkozat a személyi kedvezmény érvényesítéséről ";

  const {
    states,
    loading,
    error,
    saveErrors,
    disabledButton,
    setSaveErrors,
    resetStates,
    handleChange,
    handleSave,
    handleStateChange,
  } = useFormData({
    _id: selected?.tableId,
    setSelected,
  });

  const isModified = selected?.isModified;
  if (isModified && !states?.isModifiedDeclaration) {
    handleStateChange({ ...states, isModifiedDeclaration: true });
  }

  const showModifiedButton = states?.state === "E" ? true : false;
  const showRemoveButton =
    states?.state === "K" || selected?.sate === "K" ? true : false;

  const dateOfDeclarationString = new Date(
    states?.dateOfDeclaration
  ).toLocaleDateString();

  const componentRef = useRef();

  const { t, i18n } = useTranslation();

  const handleCancel = () => {
    resetStates();
    setSaveErrors([]);
  };

  const print = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleModChange = ({ key, value }) => {
    handleChange({ key, value });
  };

  const handlePrint = () => {
    print();
  };

  useEffect(() => {
    return () => {
      handleCancel();
    };
  }, []); // eslint-disable-line

  let { contractCode, employeeCode } = useCurrentUserState();
  const { data: contractsData } = useContractsByEmployee({
    employee: employeeCode,
  });

  let selectedContract = contractsData?.find(
    (item) => item.code === contractCode
  );

  const handleScrollToError = () => {
    const errorElement = document.getElementById("errorBox");
    if (errorElement) {
      window.scrollTo({
        top: 300,
        behavior: "smooth",
      });
    }
  };
  useLayoutEffect(() => {
    handleScrollToError();
  }, [saveErrors, history.action]);

  useEffect(() => {
    resetStates();
    if (history.action === "POP" && editable) {
      return handleCancel();
    }
  }, []); //eslint-disable-line

  return (
    <>
      <Box
        style={{
          marginBottom: "-15px",
        }}
      >
        <Box
          sx={{
            display: "none",
            displayPrint: "block",
            paddingRight: "85px",
          }}
        >
          <MyPersonalTaxCreditApplicationPrint
            ref={componentRef}
            fullName={fullName}
            taxNumber={taxNumber}
            defectiveIsPermanent={states?.isFinal}
            receiveCrippledBenefit={states?.disabilityPension}
            receiveDefectiveSupport={states?.disabilitySupport}
            crippledDecisionNumber={states?.disabilityPensionNumber}
            defectiveDecisionNumber={states?.disabilitySupportNumber}
            defectiveStart={states?.disabledStateFrom}
            defectiveEnd={states?.disabledStateTo}
            employerName={selectedContract?.company?.name || ""}
            employerTaxNumber={selectedContract?.company?.taxNumber || ""}
            isModifiedApplication={states?.isModifiedDeclaration}
            applyToWithdrawal={states?.applyToWithdrawal}
            noRequestRelief={states?.noRequestRelief}
            applicationDate={dateOfDeclarationString}
          />
        </Box>
        <ApplicationHeader
          title={title}
          onPrint={handlePrint}
          onCancel={handleCancel}
          noWrap={false}
          isMobile={isMobile}
        />
        <Box
          padding="16px"
          display="flex"
          flexDirection="row"
          justifyContent="center"
        >
          <Box
            width="260px"
            style={{
              padding: "8px",
              borderRadius: "4px",
            }}
          >
            <OcDownload
              label="Tájékoztató"
              actionIconType="url"
              href={"#/my-hr/my-personal-tax-credit-information"}
              target="_blank"
              isSmall={true}
            />
          </Box>
        </Box>
        {saveErrors.length > 0 && (
          <Box id="errorBox">
            <TaxCreditApplicationErrorBox error={saveErrors} />
          </Box>
        )}{" "}
        <OcCard
          label="I. A nyilatkozatot adó magánszemély"
          loading={loading}
          error={error}
        >
          <Box height="52px" />
          <ApplicationCardInfoItem
            label="Dátum"
            value={dateOfDeclarationString}
            isMobile={isMobile}
          />
          <ApplicationCardInfoItem
            label="Dolgozó neve"
            value={fullName}
            isMobile={isMobile}
          />
          <ApplicationCardInfoItem
            label="Adóazonosító jele"
            value={taxNumber}
            isMobile={isMobile}
          />
          <Box height="15px" />
          <ModifiedStatementCheck
            label={t("MODIFIED_APPLICATION")}
            name="isModifiedDeclaration"
            value={states?.isModifiedDeclaration}
            onChange={handleModChange}
            disabled={!editable || isModified}
            isMobile={isMobile}
          />
          <Box height="36px" />
          <Typography variant="body1">
            A bevétel adóelőlegének megállapításához kérem a súlyosan fogyatékos
            magánszemélyek személyi kedvezményének figyelembevételét.
          </Typography>
          <Box height="36px" />
          <Box display="flex" flexDirection="row">
            <Typography variant="h6">I.</Typography>
            <Box width="16px" />
            <Typography variant="h6">
              Orvosi igazolás alapján nyilatkozom
            </Typography>
          </Box>
          <Box height="36px" />
          <Box display="flex" flexDirection="row">
            <Typography variant="body1">1.</Typography>
            <Box width="16px" />
            <Typography variant="body1">
              A fogyatékos állapot kezdő napja
            </Typography>
          </Box>
          <Box height="16px" />
          <Box id="defectiveStart">
            <Box marginLeft="30px" width="200px">
              <OcDatePicker
                label="Kezdő nap"
                value={states?.disabledStateFrom || null}
                onChange={(date) =>
                  handleChange({ key: "disabledStateFrom", value: date })
                }
                format={getDateFormatByLocale(i18n.language)}
                disabled={!editable}
              />
            </Box>
          </Box>
          <Box height="36px" />
          <Box display="flex" flexDirection="row">
            <Typography variant="body1">2.</Typography>
            <Box width="16px" />
            <Typography variant="body1">
              A fogyatékos állapot végső napja
            </Typography>
          </Box>
          <Box height="16px" />
          <Box id="defectiveEnd">
            <Box marginLeft="30px" width="200px">
              <OcDatePicker
                label="Végső nap"
                value={states?.disabledStateTo || null}
                onChange={(date) =>
                  handleChange({ key: "disabledStateTo", value: date })
                }
                format={getDateFormatByLocale(i18n.language)}
                disabled={!editable}
              />
            </Box>
          </Box>
          <Box height="27px" />
          <TaxCreditApplicationCheckboxLine
            id="defectiveIsPermanent"
            type="checkbox"
            prefix="3."
            checked={states?.isFinal}
            onChange={(e) =>
              handleChange({ key: "isFinal", value: e.target.checked })
            }
            text="A fogyatékos állapot végleges"
            checkboxDisabled={!editable}
          />
          <Box height="18px" />
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            style={{ color: !editable && theme.palette.gray60.main }}
          >
            <Typography variant="h6">II.</Typography>
            <TaxCreditApplicationCheckboxLine
              id="receiveCrippledBenefit"
              type="checkbox"
              checked={states?.disabilityPension}
              checkboxDisabled={!editable}
              onChange={(e) =>
                handleChange({
                  key: "disabilityPension",
                  value: e.target.checked,
                })
              }
            />
            <Typography variant="h6">
              Rokkantsági járadékban részesülök
            </Typography>
          </Box>
          <Box height="27px" />
          <Box display="flex" flexDirection="row" alignItems="center">
            <Typography variant="body1">4.</Typography>
            <Box width="16px" />
            <Typography variant="body1">
              A rokkantsági járadékot megállapító határozat száma
            </Typography>
          </Box>
          <Box height="16px" />
          <Box marginLeft="30px">
            <TextField
              id="crippledDecisionNumber"
              variant="outlined"
              label="Határozatszám"
              type="string"
              value={states?.disabilityPensionNumber}
              onChange={(e) =>
                handleChange({
                  key: "disabilityPensionNumber",
                  value: e.target.value,
                })
              }
              style={{ width: isMobile ? "100%" : "467px" }}
              disabled={!editable}
            />
          </Box>
          <Box height="27px" />
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            style={{ color: !editable && theme.palette.gray60.main }}
          >
            <Typography variant="h6">III.</Typography>
            <TaxCreditApplicationCheckboxLine
              id="receiveDefectiveSupport"
              type="checkbox"
              checked={states?.disabilitySupport}
              checkboxDisabled={!editable}
              onChange={(e) =>
                handleChange({
                  key: "disabilitySupport",
                  value: e.target.checked,
                })
              }
            />
            <Typography variant="h6">
              Fogyatékossági támogatásban részesülök
            </Typography>
          </Box>
          <Box height="27px" />
          <Box display="flex" flexDirection="row" alignItems="center">
            <Typography variant="body1">5.</Typography>
            <Box width="16px" />
            <Typography variant="body1">
              A fogyatékossági támogatást megállapító határozat száma
            </Typography>
          </Box>
          <Box height="16px" />
          <Box marginLeft="30px">
            <TextField
              id="defectiveDecisionNumber"
              variant="outlined"
              label="Határozatszám"
              type="string"
              value={states?.disabilitySupportNumber}
              onChange={(e) =>
                handleChange({
                  key: "disabilitySupportNumber",
                  value: e.target.value,
                })
              }
              style={{ width: isMobile ? "100%" : "467px" }}
              disabled={!editable}
            />
          </Box>
          <Box height="27px" />
          <TaxCreditApplicationCheckboxLine
            id="applyToWithdrawal"
            type="checkbox"
            prefix="6."
            checked={states?.applyToWithdrawal}
            onChange={(e) =>
              handleChange({
                key: "applyToWithdrawal",
                value: e.target.checked,
              })
            }
            text="Nyilatkozatomat visszavonásig kérem figyelembe venni (folytatólagos nyilatkozatot teszek)."
            checkboxDisabled={!editable}
          />
          <Box height="18px" />
          <TaxCreditApplicationCheckboxLine
            id="noRequestRelief"
            type="checkbox"
            prefix="7."
            checked={states?.noRequestRelief}
            onChange={(e) =>
              handleChange({
                key: "noRequestRelief",
                value: e.target.checked,
              })
            }
            text="A nyilatkozattétel időpontjától kezdődően (erre a hónapra sem) a személyi kedvezményt nem kívánom igénybe venni"
            checkboxDisabled={!editable || !states?.isModifiedDeclaration}
          />
          <Box height="18px" />
          <Typography variant="h6">
            Nyilatkozat a külföldön érvényesíthető kedvezményről
          </Typography>
          <Box height="36px" />
          <Box display="flex" flexDirection="row">
            <Typography variant="body1">8.</Typography>
            <Box width="26px" />
            <Typography variant="body1">
              Nyilatkozom, hogy jogosult vagyok a személyi kedvezményt
              Magyarországon érvényesíteni, külföldi államban a jövedelmem után
              azonos vagy hasonló kedvezményt nem veszek (vettem) igénybe.
            </Typography>
          </Box>
          <Box height="26px" />
        </OcCard>
        <Box height="16px" />
        <TaxCreditApplicationButtonBars
          taxApplicationSaveEnabled={editable && taxApplicationSaveEnabled}
          cancelData={handleCancel}
          handlePrint={handlePrint}
          saveData={handleSave}
          editable={editable}
          disabledButton={disabledButton}
          handleRemoveRequest={handleRemoveRequest}
          showModifiedButton={showModifiedButton}
          showRemoveButton={showRemoveButton}
          isModified={isModified}
          isModifiedDeclaration={states?.isModifiedDeclaration}
          isMobile={isMobile}
        />
      </Box>
    </>
  );
};

export default MyPersonalTaxCreditApplication;
