import React from "react";
import OcErrorBoundary from "oc/components/oc-error-boundary";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import OcFilterItemHeader from "../oc-filter-item-header";
import OcFilterItemSearchForm from "../oc-filter-item-search-form";
import OcFilterItemSelectedList from "../oc-filter-item-selected-list";
import OcFilterItemAvailableList from "../oc-filter-item-available-list";
import OcFilterItemCheckedList from "../oc-filter-item-checked-list";
import LinearProgress from "@material-ui/core/LinearProgress";
import useIsMobile from 'hooks/use-is-mobile/use-is-mobile';

export default function OcFilterItem({
  filterType,
  selectedItems,
  availableItems,
  disabled = false,
  search,
  onSearch,
  onClearSearch,
  onAddFilter,
  onRemoveFilter,
  onLoading,
  onFetchMore,
  roleFilters,
  showLists,
  editable = true,
}) {
  const [expanded, setShowCollapse] = React.useState(false);
  const isMobile = useIsMobile();

  const handleExpandClick = () => {
    setShowCollapse(!expanded);
  };

  if (availableItems?.length === 0 && search?.length === 0) {
    disabled = true;
  }

  const filteredSelectedItems = selectedItems.filter(
    (item) => item?.name !== "empty"
  );

  if (!editable)
    return (
      <OcErrorBoundary>
        <Box
          marginRight={filterType === "contract" && !isMobile ? "8px" : "unset"}
        >
          <OcFilterItemHeader
            filterType={filterType}
            selectedItems={filteredSelectedItems}
            expanded={expanded}
            onExpandClick={handleExpandClick}
            disabled={disabled}
          />
          <Box height="2px">
            {onLoading
              &&
              <LinearProgress
                color="primary"
              />
            }
          </Box>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <OcFilterItemSelectedList
              selectedItems={filteredSelectedItems}
              filterType={filterType}
              removeItem={onRemoveFilter}
              withCode={true}
              editable={editable}
              isMobile={isMobile}
            />
          </Collapse>
        </Box>
      </OcErrorBoundary>
    );

  return (
    <OcErrorBoundary>
      <Box
        marginRight={filterType === "contract" && !isMobile ? "8px" : "unset"}
      >
        <OcFilterItemHeader
          filterType={filterType}
          selectedItems={selectedItems}
          expanded={expanded}
          onExpandClick={handleExpandClick}
          disabled={disabled}
        />
        <Box height="2px">
          {onLoading && <LinearProgress color="primary" />}
        </Box>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Box
            paddingBottom="16px"
            style={{
              backgroundColor: "#E3E5E5",
              borderRadius: "4px",
              border: "1px solid #C6CACC",
            }}
          >
            <OcFilterItemSearchForm
              search={search}
              onSearch={onSearch}
              onClearSearch={onClearSearch}
              disabled={disabled}
            />
            {showLists ? (
              <>
                <OcFilterItemAvailableList
                  selectedItems={selectedItems}
                  availableItems={availableItems}
                  addItem={onAddFilter}
                  removeItem={onRemoveFilter}
                  filterType={filterType}
                  onLoading={onLoading}
                  onFetchMore={onFetchMore}
                  showLists={showLists}
                  withCode={true}
                />
                <OcFilterItemSelectedList
                  selectedItems={selectedItems}
                  filterType={filterType}
                  removeItem={onRemoveFilter}
                  withCode={true}
                />
              </>
            ) : (
              <OcFilterItemCheckedList
                selectedItems={selectedItems}
                availableItems={availableItems}
                addItem={onAddFilter}
                removeItem={onRemoveFilter}
                filterType={filterType}
                onLoading={onLoading}
                onFetchMore={onFetchMore}
                showLists={showLists}
                roleFilters={roleFilters}
              />
            )}
          </Box>
        </Collapse>
      </Box>
    </OcErrorBoundary>
  );
}
