import moment from "moment";
import {
  overlappingTimePeriodError,
  timePeriodNotAppropriateError,
  exceedsDailyWorkTimeLimitError,
  exceedsWeeklyWorktimeLimitError,
  exceedsStandByIntervallError
} from "my-time/utils/errors";
import { getNettoWorkTime, timeDifference } from "my-time/utils";

export const validateNewRequest = ({
  requests,
  list,
  data,
  protectedTimeRanges,
  timePeriod,
  weeklyWorktimeLimit,
  weeklyVoluntaryOvertimeLimit,
  error,
  setError,
  startTime,
  endTime,
}) => {
  const DAILY_WORK_TIME_LIMIT_IN_MINUTES = 12 * 60;

  let requestsWorkTime = 0;
  let requestsVoluntaryOvertime = 0;
  let dailyWorkTime = 0;
  let weeklyWorkTime = 0;
  let weeklyVoluntaryOvertime = 0;

  const dailyList = list.filter(
    (item) =>
      moment(item.date).format("YYYY-MM-DD") ===
      moment(data?.date).format("YYYY-MM-DD")
  );

  const isOverlapped = protectedTimeRanges.find(protectedItem => {
    const startMoment = moment(startTime);
    const endMoment = moment(endTime);
    const protectedStartMoment = moment(protectedItem.startTime);
    const protectedEndMoment = moment(protectedItem.endTime);

    if (data.myTimeType === "STAND_BY_CALL" && protectedItem.myTimeType !== "WORKING_TIME") {
      return false;
    }

    const overlaps =
      (startMoment > protectedStartMoment && startMoment < protectedEndMoment) ||
      (endMoment < protectedEndMoment &&
        endMoment > protectedStartMoment &&
        data.myTimeType !== protectedItem.myTimeType);

    return overlaps && data.myTimeType !== protectedItem.myTimeType;
  });

  if (isOverlapped) {
    overlappingTimePeriodError({ error, setError });
  }

  if (data.myTimeType === "STAND_BY_CALL") {
    const isWithinStandBy = protectedTimeRanges.some((protectedItem) => {
      const startMoment = moment(startTime);
      const endMoment = moment(endTime);
      const protectedStartMoment = moment(protectedItem.startTime);
      const protectedEndMoment = moment(protectedItem.endTime);

      return (
        protectedItem.myTimeType === "STAND_BY" &&
        startMoment >= protectedStartMoment &&
        endMoment <= protectedEndMoment
      );
    });

    if (!isWithinStandBy) {
      exceedsStandByIntervallError({ error, setError });
      return;
    }
  }

  dailyList.forEach((item) => {
    const startTime = moment(item.startTime).toDate();
    const endTime = moment(item.endTime).toDate();
    const workTime =
      timeDifference({ startTime, endTime }) - item.workBreakInMinutes;
    if (
      item.myTimeType === "STAND_BY" ||
      item.myTimeType === "LEAVE" ||
      item.myTimeType === "ABSENCE" ||
      item.myTimeType === "ON_CALL"
    ) {
      return;
    } else {
      if (item.myTimeType === "WORKING_TIME") {
        const isExist = requests.find(
          (request) => request.myTimeType === "WORKING_TIME"
        );
        if (!isExist) {
          dailyWorkTime = dailyWorkTime + workTime;
        }
      } else {
        dailyWorkTime = dailyWorkTime + workTime;
      }

      if (item.timeDataTypeCode === "Tö") {
        weeklyVoluntaryOvertime = weeklyVoluntaryOvertime + workTime;
      }
    }
  });


  requests.forEach((item) => {
    const nettoWorkTime = getNettoWorkTime(item.hours);
    const startTime = moment(item.startTime).toDate();
    const endTime = moment(item.endTime).toDate();
    const workTime = nettoWorkTime;

    requestsWorkTime = requestsWorkTime + workTime;
    if (item.timeDataTypeCode === "Tö") {
      weeklyVoluntaryOvertime = weeklyVoluntaryOvertime + workTime;
      requestsVoluntaryOvertime = requestsVoluntaryOvertime + workTime;
    }
    //const isOverlappedRequests = protectedTimeRanges.find(
    // (item) =>
    //(moment(startTime) > moment(item.startTime) &&
    //moment(startTime) < moment(item.endTime)) ||
    //(moment(endTime) < moment(item.endTime) &&
    //moment(endTime) > moment(item.startTime))
    //);
    //if (isOverlappedRequests) {
    //if (item?.myTimeType !== "WORKING_TIME") {
    //overlappingTimePeriodError({ error, setError });
    //}
    //}
    const startTimeMinutesPart = moment(startTime).minutes();
    const endTimeMinutesPart = moment(endTime).minutes();
    if (timePeriod > 0) {
      const isStartTimeAppropriate = startTimeMinutesPart % timePeriod;
      const isEndTimeAppropriate = endTimeMinutesPart % timePeriod;
      if (isStartTimeAppropriate !== 0 || isEndTimeAppropriate !== 0) {
        timePeriodNotAppropriateError({ error, setError });
      }
    }
  });

  let effectiveDailyWorkTime = dailyWorkTime + requestsWorkTime;
  if (effectiveDailyWorkTime > DAILY_WORK_TIME_LIMIT_IN_MINUTES) {
    exceedsDailyWorkTimeLimitError({ error, setError });
  }

  const weeklyList = list.filter(
    (item) =>
      moment(item.date).format("YYYY-MM-DD") >=
      moment(data?.date).startOf("week").format("YYYY-MM-DD") &&
      moment(item.date).format("YYYY-MM-DD") <=
      moment(data?.date).endOf("week").format("YYYY-MM-DD")
  );
  weeklyList.forEach((item) => {
    if (
      item.myTimeType === "STAND_BY" ||
      item.myTimeType === "LEAVE" ||
      item.myTimeType === "ABSENCE" ||
      item.myTimeType === "ON_CALL"
    )
      return;
    if (item.myTimeType) {
      const startTime = moment(item.startTime).toDate();
      const endTime = moment(item.endTime).toDate();
      let workTime = 0;
      if (item.hours) {
        workTime = getNettoWorkTime(item.hours);
      } else {
        workTime =
          timeDifference({ startTime, endTime }) - item.workBreakInMinutes;
      }
      const isSame = requests.find((request) => {
        if (
          request.myTimeType === item.myTimeType &&
          moment(request.startTime).format("YYYY-MM-DD") ===
          moment(item.date).format("YYYY-MM-DD")
        ) {
          return true;
        }
        return false;
      });
      if (item.timeDataTypeCode === "Tö") {
        weeklyVoluntaryOvertime = weeklyVoluntaryOvertime + workTime;
      } else {
        if (!isSame) {
          weeklyWorkTime = weeklyWorkTime + workTime;
        }
      }
    }
  });

  const dataTime = timeDifference({ startTime, endTime });
  const cumulatedWorkTimeLimit =
    weeklyWorktimeLimit + weeklyVoluntaryOvertimeLimit;
  const cumulatedWorkTime = weeklyWorkTime + dataTime;

  if (cumulatedWorkTime > cumulatedWorkTimeLimit) {
    exceedsWeeklyWorktimeLimitError({ error, setError });
  }
};

export default validateNewRequest;
