import React, { useState, useEffect } from "react";
import { Box } from "@material-ui/core";
import OcPageTransition from "oc/components/oc-page-transition";
import OcCard from "oc/components/oc-card/oc-card";
import OcCollapseHeader from "oc/components/oc-collapse-header";
import Collapse from "@material-ui/core/Collapse";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CompanyFilter from "my-leave/components/company-filter";
import OrgUnitFilter from "my-leave/components/org-unit-filter";
import WorkingPlaceFilter from "my-leave/components/working-place-filter";
import JobClassGroupFilter from "my-leave/components/job-class-group-filter";
import PayofficeFilter from "my-leave/components/payoffice-filter";
import EmployeeFilter from "my-leave/components/employee-filter";
import FilteredListCount from "my-leave/components/my-leave-all-leaves-card/components/filtered-list-count";
import { useTranslation } from "react-i18next";
import useCurrentUserState from "hooks/use-current-user-state";
import useRoleFilters from "hooks/use-role-filters.js";
import _ from "lodash";
import useClientState from "hooks/use-oc-client-state";
import { myDocumentsUserList, myDocumentsUserListIsMobile } from "my-documents/configs/tableDefs";
import Table from "oc/components/oc-data-table";
import { initialTableData } from "my-leave/configs/my-leave-initial-table-data";
import { useHistory } from "react-router-dom";
import useUsersToMyDocumentsPages from "hooks/use-users-to-my-documents-pages/use-users-to-my-documents-pages";
import useIsMobile from 'hooks/use-is-mobile/use-is-mobile';

const MyDocumentsViewDocumentsPage = ({
  product = "MyDocuments",
  role = "viewer",
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const isMobile = useIsMobile();

  const [expanded, setExpanded] = React.useState(false);

  function handleExpandClick() {
    setExpanded(!expanded);
  }

  const maxWidthStyle = { maxWidth: isMobile ? "100%" : "50%" };

  let tableId = "myDocumentsViewerTableData";
  initialTableData.sortField = "name";
  initialTableData.pageSize = 5;

  const [tableData, setTableData] = useClientState(tableId, initialTableData);

  const [filterable, setFilterable] = useState(false);

  let { contractCode: contract } = useCurrentUserState();

  const handleCompleted = (data) => {
    handleChangeFilterable(false);
  };

  const {
    roleFilters,
    prevRoleFilters,
    resetRoleFilters,
    setPrevRoleFilters,
    handleAddFilter,
    handleRemoveFilter,
  } = useRoleFilters({
    contract: contract,
    product: product,
    role: role,
  });

  const {
    loadQuery,
    data,
    loading,
  } = useUsersToMyDocumentsPages({
    roleFilters,
    filterable,
    tableData,
    role: role,
    onCompleted: handleCompleted,
  });

  const handleClearFilters = () => {
    resetRoleFilters();
  };

  function handleChangeFilterable(state) {
    setFilterable(state);
  }

  const buttonDisable = _.isEqual(roleFilters, prevRoleFilters);

  function handleLoadQuery() {
    loadQuery();
    setPrevRoleFilters(roleFilters);
  }

  const [prevTableData, setPrevTableData] = useClientState(
    `${tableId}Prev`,
    initialTableData
  );

  const list = {
    rows: data?.rows,
    total: data?.total,
  };

  const [selectedRowId, setSelectedRowId] = useState(null);

  const originalonSelectRow = (o) => {
    history.push(`/my-documents/user-files/${o.employee}/${o.contract}`);
  };

  const onSelectRow = (o) => {
    if (isMobile) {
      setSelectedRowId(selectedRowId === o?._id ? null : o?._id);
    } else {
      originalonSelectRow(o);
    }
  };

  function handleTableDataChange(state) {
    const keys = Object.keys(state).toString();
    setTableData(state);
    if (
      keys === "searchField" ||
      (keys === "searchText" && state?.searchText?.length < 3)
    ) {
      return;
    }
    loadQuery();
  }

  const tableDef = isMobile
    ? myDocumentsUserListIsMobile
    : myDocumentsUserList;

  const filteredTableDef = {
    id: myDocumentsUserList?.id,
    columns: myDocumentsUserList?.columns?.filter(column =>
      !myDocumentsUserListIsMobile?.columns?.some(mobileColumn => mobileColumn?.id === column?.id)
    )
  };

  const isTabledataChanged = !_.isEqual(tableData, prevTableData);
  if (isTabledataChanged) {
    handleLoadQuery();
    setPrevTableData(tableData);
  }

  useEffect(() => {
    loadQuery();
    setPrevRoleFilters(roleFilters);
  }, []); // eslint-disable-line


  return (
    <OcPageTransition>
      <Box padding={isMobile ? "16px 0px" : "16px"}>
        <Box height={isMobile ? "0px" : "16px"} />
        <Box>
          <OcCard label={t("VIEWING_DOCUMENTS")} loading={loading}>
            <Box
              style={{
                backgroundColor: "#F1F3F4",
                borderRadius: "4px",
              }}
            >
              <OcCollapseHeader
                expanded={expanded}
                onExpandClick={handleExpandClick}
              />
              <Collapse
                in={expanded}
                timeout="auto"
                style={{
                  backgroundColor: "#F1F3F4",
                  borderRadius: "4px",
                }}
              >
                <Box
                  id="collapse-body"
                  padding="0px 16px"
                  display="flex"
                  flexDirection={"column"}
                  alignItems="center"
                >
                  <Box
                    style={{
                      backgroundColor: "#C6CACC",
                      height: "2px",
                      width: "100%",
                    }}
                  />
                  <Box width={isMobile ? "100%" : "66%"}>
                    <Box height="26px" />
                    <Grid
                      container
                      spacing={2}
                      style={{
                        display: "flex",
                        flexDirection: isMobile ? "column" : "unset"
                      }}
                    >
                      <Grid item xs={6} style={maxWidthStyle}>
                        <CompanyFilter
                          roleFilters={roleFilters}
                          onAddFilter={handleAddFilter}
                          onRemoveFilter={handleRemoveFilter}
                          product={product}
                          role={role}
                          contract={contract}
                        />
                      </Grid>
                      <Grid item xs={6} style={maxWidthStyle}>
                        <OrgUnitFilter
                          roleFilters={roleFilters}
                          onAddFilter={handleAddFilter}
                          onRemoveFilter={handleRemoveFilter}
                          product={product}
                          role={role}
                          contract={contract}
                        />
                      </Grid>
                      <Grid item xs={6} style={maxWidthStyle}>
                        <WorkingPlaceFilter
                          roleFilters={roleFilters}
                          onAddFilter={handleAddFilter}
                          onRemoveFilter={handleRemoveFilter}
                          product={product}
                          role={role}
                          contract={contract}
                        />
                      </Grid>
                      <Grid item xs={6} style={maxWidthStyle}>
                        <JobClassGroupFilter
                          roleFilters={roleFilters}
                          onAddFilter={handleAddFilter}
                          onRemoveFilter={handleRemoveFilter}
                          product={product}
                          role={role}
                          contract={contract}
                        />
                      </Grid>
                      <Grid item xs={6} style={maxWidthStyle}>
                        <PayofficeFilter
                          roleFilters={roleFilters}
                          onAddFilter={handleAddFilter}
                          onRemoveFilter={handleRemoveFilter}
                          product={product}
                          role={role}
                          contract={contract}
                        />
                      </Grid>
                    </Grid>
                    <Box height="16px" />
                    <Box
                      style={{
                        backgroundColor: "#C6CACC",
                        height: "1px",
                        width: "100%",
                      }}
                    />
                    <Box height="16px" />
                    <Grid item xs={6} style={maxWidthStyle}>
                      <EmployeeFilter
                        roleFilters={roleFilters}
                        onAddFilter={handleAddFilter}
                        onRemoveFilter={handleRemoveFilter}
                        product={product}
                        role={role}
                        contract={contract}
                      />
                    </Grid>
                    <Box height="16px" />
                    <Grid
                      container
                      spacing={2}
                      direction="row"
                      justifyContent="center"
                    >
                      <Grid item xs={12}>
                        <FilteredListCount
                          allUsersCount={data?.allUsersCount || 0}
                          isMobile={isMobile}
                        />
                      </Grid>
                    </Grid>
                    <Box
                      id="buttons-container"
                      display="flex"
                      flexDirection="row"
                      justifyContent="flex-end"
                      padding="26px 0px 26px 0px"
                    >
                      <Button id="cancel-button" onClick={handleClearFilters}>
                        {t("RESET")}
                      </Button>
                      <Box width="16px" />
                      <Button
                        id="filter-button"
                        variant="contained"
                        color="primary"
                        disableElevation
                        disabled={buttonDisable}
                        onClick={() => {
                          handleLoadQuery();
                          handleChangeFilterable(true);
                        }}
                      >
                        {t("FILTERING")}
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Collapse>
            </Box>
            <Box paddingTop="32px">
              <Table
                tableDef={tableDef}
                tableId={tableId}
                tableData={tableData}
                setTableData={handleTableDataChange}
                data={list}
                loading={loading}
                onSelectRow={onSelectRow}
                pagination={true}
                filteredTableDef={filteredTableDef}
                selectedRowId={selectedRowId}
                handleMoreButton={originalonSelectRow}
              />
            </Box>
          </OcCard>
        </Box>
      </Box>
    </OcPageTransition>
  );
};

export default MyDocumentsViewDocumentsPage;
