import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import AllLeavesCard from "my-leave/components/my-leave-all-leaves-card";
import AllLeavesWithCoverage from "my-leave/components/my-leave-all-leaves-with-coverage-card";
import { useTranslation } from "react-i18next";
import useClientState from "hooks/use-oc-client-state";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import { initialTableData } from "my-leave/configs/my-leave-initial-table-data";
import useUsersWithRequests from "hooks/use-my-leave-users-with-requests";
import useLeaveEvents from "hooks/use-my-leave-events";
import useCurrentUserState from "hooks/use-current-user-state";
import useRoleFilters from "hooks/use-role-filters.js";
import { currentDateState } from "states";
import { useRecoilState } from "recoil";
import moment from "moment";
import OcInfoDialog from "oc/components/oc-info-dialog/oc-info-dialog";
import useIsMobile from 'hooks/use-is-mobile/use-is-mobile';

export default function LeaveDashboard({ role }) {
  const { t } = useTranslation();
  const history = useHistory();
  const isMobile = useIsMobile();

  const [currentDate, setCurrentDate] = useRecoilState(currentDateState);
  const [prevCurrentDate, setPrevCurrentDate] = useState(currentDate);
  const [showDialog, setShowDialog] = useState(false);

  let { contractCode: contract } = useCurrentUserState();

  const {
    roleFilters,
    prevRoleFilters,
    resetRoleFilters,
    setPrevRoleFilters,
    handleAddFilter,
    handleRemoveFilter,
  } = useRoleFilters({
    contract,
    product: "MyLeave",
    role: role,
  });

  const [filterable, setFilterable] = useClientState("allLeavesFilter", false);

  const [filterItemErrors, setFilterItemErrors] = useState([]);

  let tableId =
    role === "examiner" ? "allLeavesTableData" : "allLeavesViewerTableData";
  initialTableData.sortField = "name";
  initialTableData.pageSize = 5;

  const [tableData, setTableData] = useClientState(tableId, initialTableData);
  const [prevTableData, setPrevTableData] = useClientState(
    `${tableId}Prev`,
    initialTableData
  );

  const handleCompleted = (data) => {
    handleChangeFilterable(false);
  };

  const {
    loadQuery: loadUsers,
    data,
    loading,
  } = useUsersWithRequests({
    roleFilters,
    currentDate,
    filterable,
    tableData,
    adminView: role === "examiner" ? false : true,
    role: role,
    dashboard: true,
    startDate: moment(currentDate).startOf("month").toDate(),
    endDate: moment(currentDate).endOf("year").add(1, "year").toDate(),
    onCompleted: handleCompleted,
  });

  const {
    loadEvents,
    data: leavesData,
    loading: leaveEventsLoading,
    eventsByDays,
  } = useLeaveEvents({
    roleFilters,
    viewedByAdmin: role === "examiner" ? false : true,
    currentDate: moment(currentDate).toDate(),
    contract: contract,
    role: role
  });

  const MAX_ITEM_COUNT = 1000;
  const generateRoleFilterError = () => {
    const newState = [...filterItemErrors];
    if (roleFilters?.company?.length > MAX_ITEM_COUNT) {
      newState.push(t("COMPANY"));
      setFilterItemErrors(newState);
    }
    if (roleFilters?.orgUnit?.length > MAX_ITEM_COUNT) {
      newState.push(t("ORGUNIT"));
      setFilterItemErrors(newState);
    }

    if (roleFilters?.workingPlace?.length > MAX_ITEM_COUNT) {
      newState.push(t("WORKINGPLACE"));
      setFilterItemErrors(newState);
    }
    if (roleFilters?.jobClassGroup?.length > MAX_ITEM_COUNT) {
      newState.push(t("JOBCLASSGROUP"));
      setFilterItemErrors(newState);
    }
    if (roleFilters?.payOffice?.length > MAX_ITEM_COUNT) {
      newState.push(t("PAYOFFICE"));
      setFilterItemErrors(newState);
    }
    if (roleFilters?.contract?.length > MAX_ITEM_COUNT) {
      newState.push(t("GET_EMPLOYEE"));
      setFilterItemErrors(newState);
    }

    return newState?.length > 0 ? newState : false;
  };

  const handleLoadQuery = () => {
    const isItemError = generateRoleFilterError();
    if (isItemError) {
      setShowDialog(true);
      handleChangeFilterable(false);
      return;
    }
    loadUsers();
    loadEvents();
    setPrevRoleFilters(roleFilters);
  };

  const buttonDisable = _.isEqual(roleFilters, prevRoleFilters);

  function handleChangeFilterable(state) {
    setFilterable(state);
  }

  function handleCurrentDateChange(date) {
    setCurrentDate(date);
  }

  useEffect(() => {
    if (prevCurrentDate !== currentDate) {
      loadEvents();
      setPrevCurrentDate(currentDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDate, prevCurrentDate]);

  function handleTableDataChange(state) {
    const keys = Object.keys(state).toString();
    setTableData(state);
    if (
      keys === "searchField" ||
      (keys === "searchText" && state?.searchText?.length < 3)
    ) {
      return;
    }
    loadUsers();
    loadEvents();
  }

  const handleClearFilters = () => {
    resetRoleFilters();
    handleChangeFilterable(false);
    setFilterItemErrors([]);
  };

  function handleEventSelected(event) {
    let { _id, contract } = event;
    let url = `/my-leave/user-request/MyLeave/${role}/${contract}/${_id}`;
    history.push(url);
  }

  const isTabledataChanged = !_.isEqual(tableData, prevTableData);
  useEffect(() => {
    if (isTabledataChanged) {
      handleLoadQuery();
      setPrevTableData(tableData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTabledataChanged]);

  const handleDialogClose = () => {
    setShowDialog(false);
    setFilterItemErrors([]);
  };

  const [selectedRowId, setSelectedRowId] = useState(null);

  function handleRowSelection(row) {
    if (role === "assigner") {
      if (selectedRowId === null || selectedRowId !== row?.contract) {
        setSelectedRowId(row?.contract);
      }
    } else {
      setSelectedRowId(selectedRowId === row?.contract ? null : row?.contract);
    }
  }

  useEffect(() => {
    loadUsers();
    loadEvents();
    setPrevRoleFilters(roleFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <OcInfoDialog
        open={showDialog}
        text={t("TOO_MANY_HITS")}
        handleDialogClose={handleDialogClose}
        isMobile={isMobile}
        filterItemErrors={filterItemErrors}
      />
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        flexGrow={1}
      >
        <Box>
          <AllLeavesCard
            label={t("MY_ROLES_EXAMINEES")}
            buttonDisable={buttonDisable}
            currentDate={currentDate}
            data={data}
            filterable={filterable}
            loading={loading}
            monthFiltering={false}
            onAddFilter={handleAddFilter}
            onClearFilters={handleClearFilters}
            onCurrentDateChange={handleCurrentDateChange}
            onFilterableChange={handleChangeFilterable}
            onLoadQuery={handleLoadQuery}
            onRemoveFilter={handleRemoveFilter}
            onSelectRow={handleRowSelection}
            onTableDataChange={handleTableDataChange}
            product={"MyLeave"}
            role={role}
            roleFilters={roleFilters}
            tableData={tableData}
            tableId={tableId}
            contract={contract}
            selectedRowId={selectedRowId}
            onRowSelection={handleRowSelection}
            examinerDasboard={true}
          />
          <Box height="16px" />
          <AllLeavesWithCoverage
            cardTitle={t("LEAVES_AND_REMOTE_WORKS")}
            currentDate={currentDate}
            onCurrentDateChange={handleCurrentDateChange}
            roleFilters={roleFilters}
            viewedByAdmin={role === "examiner" ? false : true}
            showRemoteWorks={false}
            onEventSelected={handleEventSelected}
            leavesData={leavesData}
            eventsByDays={eventsByDays}
            loading={leaveEventsLoading}
          />
        </Box>
      </Box>
    </>
  );
}
