import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import OrgUnitFilter from "my-hr/components/my-hr-org-unit-filter";
import WorkingPlaceFilter from "my-hr/components/my-hr-working-place-filter";
import JobClassGroupFilter from "my-hr/components/my-hr-job-class-group-filter";
import PayOfficeFilter from "my-hr/components/my-hr-pay-office-filter";
import EmployeeFilter from "my-hr/components/my-hr-employee-filter";
import { useTranslation } from "react-i18next";
import { useTheme } from "@material-ui/styles";
import useClientState from "hooks/use-oc-client-state-2";
import _ from "lodash";
import OcErrorBoundary from "oc/components/oc-error-boundary";
import CompanyFilter from "my-hr/components/my-hr-company-filter";
import MyLeaveFilterDivider from "my-leave/components/my-leave-filter-divider";
import { useLocation } from "react-router-dom";
import useCurrentUserState from "hooks/use-current-user-state";
import useIsMobile from 'hooks/use-is-mobile/use-is-mobile';
import MyHrUserProfilesRolesItemHeader from "my-hr/components/my-hr-user-profile-roles/components/my-hr-user-profiles-roles-item-header/my-hr-user-profiles-roles-item-header";
import accountIcon from "@iconify/icons-mdi/account";
import OcConfirmationDialog from "oc/components/oc-confirmation-dialog";

export default function RoleCollapse({
  role,
  index,
  roleIndex,
  onUpdateRole,
  onRemoveRole,
  unsavedRole,
  showUnsavedRole,
  onAddUnsavedRole,
  onRemoveUnsavedRole,
  needToSave,
  skipInitTempState,
  editable = true,
  product,
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const location = useLocation();
  const isMobile = useIsMobile();

  const maxWidthStyle = { maxWidth: isMobile ? "100%" : "50%" };

  const isMyHrAdmin = location?.pathname?.startsWith("/my-hr/user-profile");
  let { contractCode: currentUserContract } = useCurrentUserState();

  let stateId =
    "showCollapse" + role?.product + role?.role + role?.contract + index;

  const [showCollapse, setShowCollapse] = useClientState(stateId, false);

  let tempStateId =
    "showCollapse" +
    role?.product +
    role?.role +
    role?.contract +
    index +
    "tempState";

  const [tempState, setTempState] = useClientState(tempStateId, role);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  //function handleSetState(o) {
  //  setTempState(o);
  //}

  const handleExpandClick = () => {
    setShowCollapse(!showCollapse);
  };

  function handleRestore() {
    setTempState(role);
    onRemoveUnsavedRole(role);
  }

  useEffect(() => {
    if (!tempState && role) {
      setTempState(role);
    }
    if (roleIndex === 0) {
      setShowCollapse(true);
    }
    // eslint-disable-next-line
  }, []);


  function handleRemoveRole() {
    setTempState(role);
    onRemoveRole(tempState);
  }

  let isMyHRUser = role?.product === "MyHR" && role?.role === "user";
  let isMyHRAdmin = role?.product === "MyHR" && role?.role === "admin";
  let isMyLeaveAdmin = role?.product === "MyLeave" && role?.role === "admin";
  let isUser = role?.role === "user";
  let isMyCafeteriaAdmin =
    role?.product === "MyCafeteria" && role?.role === "admin";

  let isInUnsavedRole =
    unsavedRole.roles.filter(
      (item) =>
        item.contract === tempState.contract &&
        item.role === tempState.role &&
        item.product === tempState.product
    ).length > 0
      ? true
      : false;

  let showBorder = isInUnsavedRole && showUnsavedRole;

  if (!_.isEqual(role, tempState) && !skipInitTempState) {
    if (!isInUnsavedRole) {
      onAddUnsavedRole(tempState);
    }
  }

  let isExact =
    role?.product === tempState?.product &&
    role?.contract === tempState?.contract &&
    role.role === tempState?.role &&
    _.isEqual(role?.roleFilters, tempState?.roleFilters);

  let showCollapseButton = true;

  function handleSaveRole(showDialog = true, isInUnsavedRole = false) {
    let newState = _.cloneDeep(tempState);
    newState.roleId = role.roleId;

    const isEmptyFilters = Object.entries(tempState?.roleFilters || {})
      .filter(([key]) => key !== "__typename")
      .every(([, filter]) => Array.isArray(filter) && filter.length === 0);

    if (
      ['examiner', 'viewer', 'uploader', 'assigner'].includes(role.role) &&
      showDialog
    ) {
      if (isEmptyFilters || isInUnsavedRole) {
        setShowConfirmDialog(true);
        return;
      }
    }

    onUpdateRole(newState);
    onRemoveUnsavedRole(tempState);
  }

  if (!isMyHrAdmin) {
    if (
      (role?.product === "MyLeave" && role?.role === "admin") ||
      (role?.product === "MyHR" && role?.role === "admin") ||
      (role?.product === "MyCafeteria" && role?.role === "admin")
    ) {
      showCollapseButton = false;
    }

    if (role?.role === "user") {
      showCollapseButton = false;
    }
  } else {
    if (
      role?.product === "MyHR" &&
      role?.role === "admin" &&
      role?.contract === currentUserContract
    ) {
      showCollapseButton = false;
    }
    if (role?.product === "MyHR" && role?.role === "user") {
      showCollapseButton = false;
    }
  }


  function handleDialogConfirm() {
    setShowConfirmDialog(false);
    handleSaveRole(false);
  }

  function handleDialogCancel() {
    setShowConfirmDialog(false);
  }

  return (
    <OcErrorBoundary>
      <OcConfirmationDialog
        showDialog={showConfirmDialog}
        onClose={handleDialogConfirm}
        onCancel={handleDialogCancel}
        title={t("CONFIRM_SETTING")}
        text={t("ADD_ALL_PERMISSIONS_DIALOG_INFO")}
        okButtonText={t("ALL_RIGHT")}
        cancelButtonText={t("CANCEL")}
      />
      <Box
        style={{
          backgroundColor: theme.palette.gray20.main,
          borderRadius: "4px",
          border: showBorder ? "1px solid red" : "0px solid transparent",
          width: isMobile ? "100%" : "728px",
        }}
      >
        <OcErrorBoundary>
          <MyHrUserProfilesRolesItemHeader
            product={role?.product}
            role={role?.role}
            showCollapseButton={showCollapseButton}
            onExpand={handleExpandClick}
            showCollapse={showCollapse}
            isMobile={isMobile}
            roleIcon={accountIcon}
          />
        </OcErrorBoundary>
        <OcErrorBoundary>
          <Collapse in={showCollapse} timeout="auto" unmountOnExit>
            {isMyHRUser ||
              isUser ||
              isMyHRAdmin ||
              isMyLeaveAdmin ||
              isMyCafeteriaAdmin ? (
              <Box padding="0px 16px">
                <Box
                  height={isMobile ? "100%" : "88px"}
                  display="flex"
                  flexDirection={isMobile ? "column-reverse" : "row"}
                  justifyContent={isMobile ? "center" : "flex-end"}
                  alignItems="center"
                  style={{
                    borderTop: `2px solid ${theme.palette.gray40.main}`,
                    padding: isMobile ? "16px 0px" : "16px 16px",
                  }}
                >
                  <Button
                    color="primary"
                    onClick={handleRemoveRole}
                    disabled={isMyHRUser}
                  >
                    {t("DELETE")}
                  </Button>
                  {needToSave && (
                    <>
                      <Box height="16px" />
                      <Button
                        variant="contained"
                        color="primary"
                        disableElevation
                        disabled={needToSave ? !isInUnsavedRole : isExact}
                        style={{ marginLeft: isMobile ? 0 : 16, width: isMobile ? "100%" : "auto" }}
                        onClick={handleSaveRole}
                      >
                        {t("SAVE")}
                      </Button>
                    </>
                  )}
                </Box>
              </Box>
            ) : (
              <Box padding="0px 16px">
                <Box
                  style={{
                    borderTop: `2px solid ${theme.palette.gray40.main}`,
                    padding: "16px 16px",
                  }}
                >
                  {showCollapseButton && (
                    <OcErrorBoundary>
                      <Grid
                        container
                        spacing={2}
                        style={{
                          display: "flex",
                          flexDirection: isMobile ? "column" : "unset"
                        }}
                      >
                        <Grid item xs={6} style={maxWidthStyle}>
                          <CompanyFilter
                            role={tempState}
                            onUpdateRole={setTempState}
                            editable={editable}
                            product={product}
                          />
                        </Grid>
                        <Grid item xs={6} style={maxWidthStyle}>
                          <OrgUnitFilter
                            role={tempState}
                            onUpdateRole={setTempState}
                            editable={editable}
                            product={product}
                          />
                        </Grid>
                        <Grid item xs={6} style={maxWidthStyle}>
                          <WorkingPlaceFilter
                            role={tempState}
                            onUpdateRole={setTempState}
                            editable={editable}
                          />
                        </Grid>
                        <Grid item xs={6} style={maxWidthStyle}>
                          <JobClassGroupFilter
                            role={tempState}
                            onUpdateRole={setTempState}
                            editable={editable}
                          />
                        </Grid>
                        <Grid item xs={6} style={maxWidthStyle}>
                          <PayOfficeFilter
                            role={tempState}
                            onUpdateRole={setTempState}
                            editable={editable}
                          />
                        </Grid>
                      </Grid>
                      <Box height="16px" />
                      <MyLeaveFilterDivider />
                      <Box height="16px" />
                      <Grid item xs={6} style={maxWidthStyle}>
                        <EmployeeFilter
                          role={tempState}
                          onUpdateRole={setTempState}
                          editable={editable}
                        />
                      </Grid>
                    </OcErrorBoundary>
                  )}
                  <Box height="16px" />
                  <Box style={{ display: "flex", justifyContent: "center" }}>
                    {/*       <Grid item xs={12}>
                      <FilteredListCount listCount="00" />
                    </Grid>
                */}{" "}
                  </Box>
                </Box>
                {editable && (
                  <Box
                    padding="26px 0px"
                    display="flex"
                    flexDirection={isMobile ? "column-reverse" : "row"}
                    justifyContent="flex-end"
                  >
                    <Button
                      id="handleRemoveRole-delete-button"
                      color="primary"
                      onClick={handleRemoveRole}
                    >
                      {t("DELETE")}
                    </Button>
                    {isMobile && <Box height="16px" />}
                    {!isExact && (
                      <>
                        <Button
                          id="handleRestore-restore-button"
                          onClick={handleRestore}
                          style={{ marginLeft: isMobile ? "0px" : "16px" }}
                        >
                          {t("RESTORE")}
                        </Button>
                        {isMobile && <Box height="16px" />}
                      </>
                    )}
                    <Button
                      id="handleSaveRole-save-button"
                      variant="contained"
                      color="primary"
                      disableElevation
                      disabled={needToSave ? !isInUnsavedRole : isExact}
                      style={{ marginLeft: isMobile ? "0px" : "16px" }}
                      onClick={handleSaveRole}
                    >
                      {t("SAVE")}
                    </Button>
                  </Box>
                )}
              </Box>
            )}
            {!isExact && isMobile && <Box height="16px" />}
          </Collapse>
        </OcErrorBoundary>
      </Box>
    </OcErrorBoundary>
  );
}
