import React from "react";
import OcErrorBoundary from "oc/components/oc-error-boundary";
import { GET_ADMIN_LIST_COMPANIES } from "graphql/queries";
import { useQuery } from "@apollo/client";
import usePolling from "hooks/use-polling";
import FilterItem from "oc/components/oc-filter-item";
import useFiltersData from "hooks/use-filters-data";
import useQueryParams from "hooks/use-query-params";
import useFilterChange from "hooks/use-filter-change";

export default function MyHrOrgUnitFilter({ role, onUpdateRole }) {
  const filterType = "company";
  const entity = { companies: role?.roleFilters?.company || [] };
  const stateId = "myHrCompanyFilter";
  const { search, setSearch, setPageSize, queryParams } = useQueryParams({
    stateId,
    entity,
    product: role?.product,
    role: role?.role,
    roleFilters: role?.rolefilters,
    contract: role?.contract,
  });

  const {
    data: rawData,
    loading,
    fetchMore,
  } = useQuery(GET_ADMIN_LIST_COMPANIES, {
    skip: !role.product || !role?.role,
    fetchPolicy: "no-cache",
    pollInterval: usePolling(1000 * 60),
    variables: queryParams,
  });

  const data = rawData?.adminListCompanies;

  const {
    availableItems,
    selectedItems,
    handleFetchMore,
    handleSearch,
    handleClearSearch,
  } = useFiltersData({
    data,
    fetchMore,
    setSearch,
    setPageSize,
    product: role?.product,
    role: role?.role,
    filterType,
    contract: role?.contract,
  });

  const { handleAddFilter, handleRemoveFilter } = useFilterChange({
    role,
    onUpdateRole,
  });

  return (
    <OcErrorBoundary>
      <FilterItem
        filterType={filterType}
        selectedItems={selectedItems}
        availableItems={availableItems}
        search={search}
        onLoading={loading}
        onSearch={handleSearch}
        onClearSearch={handleClearSearch}
        onAddFilter={handleAddFilter}
        onRemoveFilter={handleRemoveFilter}
        withCode={true}
        onFetchMore={handleFetchMore}
        showLists={true}
      />
    </OcErrorBoundary>
  );
}
