import React from "react";
import useMyTimeMessagesList from "my-time/hooks/use-my-time-messages-list";
import { InlineIcon } from "@iconify/react";
import MessageOutlineIcon from "@iconify/icons-mdi/message-outline";
import MessageTextIcon from "@iconify/icons-mdi/message-text";

const MyTimeMessagesIndicator = ({ row }) => {
  const { data: messages } = useMyTimeMessagesList({ request: row._id });
  return (
    <InlineIcon
      width="20px"
      height="20px"
      icon={messages?.length > 0 ? MessageTextIcon : MessageOutlineIcon}
    />
  );
};

export default MyTimeMessagesIndicator;
