import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import OcCard from "oc/components/oc-card";
import { useTranslation } from "react-i18next";
import useUserRolesDataByContract from "hooks/use-myhr-user-roles-data-by-contract";
import RoleCollapse from "my-hr/pages/my-hr-create-new-user-page/components/role-collapse";
import useClientState from "hooks/use-oc-client-state-2";
import MyHrAddUserRole from "my-hr/components/my-hr-add-user-role";
import _ from "lodash";
import OcErrorBoundary from "oc/components/oc-error-boundary";
import { useMutation } from "@apollo/react-hooks";
import {
  CREATE_USER_ROLE_BY_CONTRACT,
  DELETE_USER_ROLE_BY_CONTRACT,
  UPDATE_USER_ROLE_BY_CONTRACT,
} from "graphql/mutations";
import NavigationPrompt from "react-router-navigation-prompt";
import useOcSnackbar from "hooks/use-oc-snackbar";
import OcInfoDialog from "oc/components/oc-info-dialog/oc-info-dialog";

export default function MyHrEditRoleForm({ contract, editable = true, isMobile }) {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const { data: rolesData, refetch } = useUserRolesDataByContract({
    contract: contract?.code,
  });

  const { setOpenMessage } = useOcSnackbar();

  const [createUserRoleByContract] = useMutation(CREATE_USER_ROLE_BY_CONTRACT, {
    onCompleted: (data) => {
      setLoading(false);
      refetch();
      setOpenMessage({
        type: "success",
        message: t("ROLE_CREATED_SUCCESSFULLY"),
      });
    },
    onError: (error) => {
      setLoading(false);
      setOpenMessage({
        type: "error",
        message: t("ROLE_CREATION_FAILED"),
      });
    },
  });

  const [deleteUserRoleByContract] = useMutation(DELETE_USER_ROLE_BY_CONTRACT, {
    onCompleted: (data) => {
      setLoading(false);
      refetch();
      setOpenMessage({
        type: "success",
        message: t("ROLE_REMOVED_SUCCESSFULLY"),
      });
    },
    onError: (error) => {
      setLoading(false);
      setOpenMessage({
        type: "error",
        message: t("ROLE_REMOVE_FAILED"),
      });
    },
  });

  const [updateUserRoleByContract] = useMutation(UPDATE_USER_ROLE_BY_CONTRACT, {
    onCompleted: (data) => {
      setLoading(false);
      refetch();
      setOpenMessage({
        type: "success",
        message: t("ROLE_UPDATED_SUCCESSFULLY"),
      });
    },
    onError: (error) => {
      setLoading(false);
      setOpenMessage({
        type: "error",
        message: t("ROLE_UPDATE_FAILED"),
      });
    },
  });

  let jobClassName =
    contract?.jobClass?.name.charAt(0).toUpperCase() +
    contract?.jobClass?.name.slice(1) || "";

  const [unsavedRole, setUnsavedRole] = useClientState("unsavedRole", {
    type: "unsavedRoles",
    roles: [],
  });

  const [showUnsavedRole, setShowUnsavedRole] = useClientState(
    "showUnsavedRole",
    false
  );

  useEffect(() => {
    return () => {
      setUnsavedRole({
        type: "unsavedRoles",
        roles: [],
      });
      setShowUnsavedRole(false);
    };
    // eslint-disable-next-line
  }, []);

  function handleAddRole({ contract, module, role }) {
    let newRole = {
      contract: contract,
      product: module,
      role: role,
      roleFilters: {
        company: [],
        orgUnit: [],
        workingPlace: [],
        jobClassGroup: [],
        payOffice: [],
        contract: [],
      },
    };

    let tempClone = _.cloneDeep(unsavedRole);
    tempClone.roles.unshift(newRole);
    setUnsavedRole(tempClone);
  }

  function handleUpdateRole(role) {
    let isExist = rolesData?.find(
      (item) =>
        item.contract === role.contract &&
        item.product === role.product &&
        item.role === role.role
    );

    if (isExist) {
      let newState = {
        roleId: role?.roleId,
        contract: role?.contract,
        product: role?.product,
        role: role?.role,
        roleFilters: role?.roleFilters,
      };
      delete newState.roleFilters.__typename;
      setLoading(true);
      updateUserRoleByContract({ variables: newState });
    } else {
      setLoading(true);
      createUserRoleByContract({ variables: role });
      handleRemoveUnsavedRole(role);
    }
  }

  function handleRemoveUnsavedRole(role) {
    let tempClone = _.cloneDeep(unsavedRole);
    let filtered = unsavedRole.roles.filter((item) => {
      if (
        item.contract === role.contract &&
        item.role === role.role &&
        item.product === role.product
      ) {
        return null;
      } else {
        return item;
      }
    });
    tempClone.roles = filtered;
    setUnsavedRole(tempClone);
  }

  function handleAddUnsavedRole(role) {
    let tempClone = _.cloneDeep(unsavedRole);
    tempClone.roles.push(role);
    setUnsavedRole(tempClone);
  }

  function handleRemoveRole(role) {
    let existedRole = rolesData?.find(
      (item) =>
        item.contract === role.contract &&
        item.role === role.role &&
        item.product === role.product
    );
    setLoading(true);
    deleteUserRoleByContract({ variables: { roleId: existedRole.roleId } });
    handleRemoveUnsavedRole(role);
  }

  const filteredUnsavedRoles = unsavedRole?.roles.filter(
    (role) => role.contract === contract?.code
  );

  const filteredRolesData = rolesData?.filter(
    (role) => role.contract === contract?.code
  );

  let allRoles = filteredUnsavedRoles.concat(filteredRolesData);

  return (
    <OcErrorBoundary>
      <NavigationPrompt
        beforeConfirm={(clb) => this.cleanup(clb)} //call the callback function when finished cleaning up
        // Children will be rendered even if props.when is falsey and isActive is false:
        renderIfNotActive={true}
        // Confirm navigation if going to a path that does not start with current path:
        when={unsavedRole?.roles?.length > 0}
      >
        {({ isActive, onCancel, onConfirm }) => {
          if (isActive) {
            return (
              <OcInfoDialog
                open={true}
                text={t("UNSAVED_ELEMENTS")}
                handleDialogClose={() => {
                  setShowUnsavedRole(true);
                  return onCancel();
                }}
                isMobile={isMobile}
              />
            );
          }
          return null;
        }}
      </NavigationPrompt>
      <Box
        style={{
          padding: editable && !isMobile ? "0px 16px 16px 16px" : "0px",
        }}
      >
        <OcCard
          label={`${jobClassName} - ${contract?.company?.name} (${contract?.code
            }) - ${t("ROLES")}`}
          loading={loading}
        >
          <>
            {editable && (
              <MyHrAddUserRole
                roles={allRoles || []}
                setUser={() => console.log("bump")}
                contract={contract}
                onAddRole={handleAddRole}
                isMobile={isMobile}
              />
            )}
            {filteredUnsavedRoles.map((item, index) => {
              let isExist = filteredRolesData.find(
                (roleItem) =>
                  roleItem.contract === item.contract &&
                  roleItem.product === item.product &&
                  roleItem.role === item.role
              );
              if (isExist) return null;
              return (
                <Box
                  key={`${item.contract}-${item.product}-${item.role}`}
                  marginBottom="16px"
                  display="flex"
                  alignItems="center"
                  flexDirection="column"
                >
                  <RoleCollapse
                    editable={editable}
                    role={item}
                    roleIndex={0}
                    index={0}
                    onUpdateRole={handleUpdateRole}
                    onRemoveRole={handleRemoveUnsavedRole}
                    unsavedRole={unsavedRole}
                    onAddUnsavedRole={handleAddUnsavedRole}
                    onRemoveUnsavedRole={handleRemoveUnsavedRole}
                    showUnsavedRole={showUnsavedRole}
                    needToSave={true}
                    product={item.product}
                  />
                </Box>
              );
            })}

            {filteredRolesData?.map((item, index) => (
              <Box
                key={`${item.contract}-${item.product}-${item.role}`}
                marginBottom="16px"
                display="flex"
                alignItems="center"
                flexDirection="column"
              >
                <RoleCollapse
                  role={item}
                  roleIndex={999}
                  index={999}
                  onUpdateRole={handleUpdateRole}
                  onRemoveRole={handleRemoveRole}
                  unsavedRole={unsavedRole}
                  onAddUnsavedRole={handleAddUnsavedRole}
                  onRemoveUnsavedRole={handleRemoveUnsavedRole}
                  showUnsavedRole={false}
                  needToSave={false}
                  skipInitTempState={true}
                  editable={editable}
                  product={item.product}
                />
              </Box>
            ))}
          </>
        </OcCard>
        {isMobile && <Box height="16px" />}
      </Box>
    </OcErrorBoundary>
  );
}
