import { useTheme } from "@material-ui/styles";
import { InlineIcon } from "@iconify/react";
import documentIcon from "@iconify/icons-mdi/file-document";
import rightIcon from "@iconify/icons-mdi/chevron-right";
import useSelectedTaxDeclaration from "hooks/use-selected-tax-declaration";
import useTaxApplications from "hooks/use-tax-applications";
import useIsMobile from 'hooks/use-is-mobile/use-is-mobile';
import { Box, Typography } from "@material-ui/core";

const TaxApplicationSelector = ({ label, type }) => {
  const theme = useTheme();
  const isMobile = useIsMobile();

  const { selected, setSelected } = useSelectedTaxDeclaration();

  const { taxApplications } = useTaxApplications();
  const enabled = taxApplications[type];

  const handleClick = () => {
    if (!enabled) return;
    const newState = { ...selected, editable: true, type };
    setSelected(newState);
  };

  const modifiedLabel = label?.replace(/[áéíóöőúüűÁÉÍÓÖŐÚÜŰ]/g, (match) => {
    const characherCorrection = {
      'á': 'a', 'é': 'e', 'í': 'i', 'ó': 'o', 'ö': 'o', 'ő': 'o',
      'ú': 'u', 'ü': 'u', 'ű': 'u', 'Á': 'A', 'É': 'E', 'Í': 'I',
      'Ó': 'O', 'Ö': 'O', 'Ő': 'O', 'Ú': 'U', 'Ü': 'U', 'Ű': 'U'
    };
    return characherCorrection[match];
  });

  return (
    <Box
      id={modifiedLabel?.replace(/\s+/g, '')}
      height={isMobile ? "100%" : "72px"}
      display="flex"
      flexDirection="row"
      alignItems="center"
      onClick={handleClick}
      style={{
        backgroundColor: theme.palette.gray20.main,
        border: `1px solid ${theme.palette.gray40.main}`,
        display: "flex",
        borderRadius: "8px",
        color: enabled
          ? theme.palette.darkBase.main
          : theme.palette.gray60.main,
        cursor: "pointer",
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        width="40px"
        height="40px"
        marginLeft="7px"
      >
        <InlineIcon icon={documentIcon} width="20px" height="20px" />
      </Box>
      {isMobile && <Box width="16px" />}
      <Box flexGrow={1} display="flex" flexDirection="column" padding={isMobile ? "16px 0px" : "unset"}
      >
        <Typography variant="body1">{label}</Typography>
      </Box>
      <Box width="10px" />
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        width="40px"
        height="40px"
        style={{
          marginRight: "10px",
        }}
      >
        <InlineIcon
          icon={rightIcon}
          width="18px"
          height="18px"
          style={
            enabled
              ? { color: theme.palette.darkBase.main }
              : { color: theme.palette.gray60.main }
          }
        />
      </Box>
    </Box>
  );
};

export default TaxApplicationSelector;
