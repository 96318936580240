import gql from "graphql-tag";

export const APPROVE_LEAVE_REQUEST = gql`
  mutation approveLeaveRequest(
    $_id: String!
    $message: String
    $locale: String
    $closedByAdmin: Boolean
    $role: String
  ) {
    approveLeaveRequest(
      _id: $_id
      message: $message
      locale: $locale
      closedByAdmin: $closedByAdmin
      role: $role
    ) {
      _id
      state
      approvedAt
      approvedBy
    }
  }
`;
